import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';
import { InfoModal } from '../components/InfoModal';

type Props = {
  className?: string;
  absolute?: boolean;
  size?: SizeProp;
};
const LookalikeDomainInfo: FC<Props> = (props) => (
  <InfoModal color={'rgba(17, 138, 245, 0.5)'} {...props} title={'Domini simili'}>
    <p>
      Può trattarsi di un tentativo di truffa con cui l'hacker registra un dominio simile a quello
      di una organizzazione e successivamente inganna le vittime facendole credere di essere sul
      sito web originale.
    </p>
  </InfoModal>
);

export { LookalikeDomainInfo };

import { FC, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useAPI } from 'src/_metronic/layout/core';
import { useAuth } from 'src/app/modules/auth';
import { getUserByToken } from 'src/app/modules/auth/core/_requests';

const PDFRoot: FC = () => {
  const { api } = useAPI();

  const [params] = useSearchParams();

  const { currentUser, saveAuth, auth, setCurrentUser } = useAuth();

  const token = params.get('token');

  console.log(params);

  if (token === null) {
    return <>Invalid authentication {JSON.stringify(params)}</>;
  }

  useEffect(() => {
    saveAuth({
      api_token: token
    });
    getUserByToken(api, token).then((response) => {
      setCurrentUser(response.data);
    });
  }, []);


  if (!currentUser) {
    return <>Waiting...</>;
  }

  return (
    <Navigate
      to={'/pdf'}
      state={{
        uuid: params.get('uuid'),
        pdf: true
      }}
    />
  );
};

export { PDFRoot };

import React, { FC } from 'react';

const BestPractices: FC = () => {
  return (
    <>
      <h3>Analisi dei rischi cyber</h3>
      <p>
        Processo che permette la valutazione del sistema informativo di un'organizzazione mirata ad
        individuare potenziali rischi informatici per il business aziendale. È il primo step da
        compiere quando si vuole implementare un progetto di cyber security aziendale.
      </p>

      <h3>Firewall di rete </h3>
      <p>
        Un firewall di rete è una pratica importante, poiché offre il controllo assoluto sul
        traffico di rete all'interno della rete aziendale. Un buon firewall di rete monitora
        autonomamente il traffico in entrata e in uscita da e verso la rete e controlla i dati. In
        questo modo, impedisce ai malintenzionati di accedere alle aree di rete protette.{' '}
      </p>

      <h3>Valutazione della vulnerabilità (VA)</h3>
      <p>
        Il Vulnerability Assessment ha come obiettivo l'identificazione di tutte le vulnerabilità
        potenziali dei sistemi digitali aziendali valutando il danno potenziale che l'eventuale
        “attaccante” può infliggere all'unità produttiva. Questa attività permette di scovare
        all'interno o all'esterno di un'organizzazione gli eventuali errori di programmazione o
        errate configurazioni, commessi durante un'installazione o un upgrade dei sistemi
        informativi.
      </p>

      <h3>Test di penetrazione (PT)</h3>
      <p>
        Il Penetration Test consiste in una simulazione di attacco informatico da parte di un
        criminal hacker ai sistemi digitali o alla rete informatica dell'organizzazione. Il test ha
        l'obiettivo di evidenziare le debolezze dei sistemi IT aziendali fornendo il maggior numero
        di informazioni sulle vulnerabilità che ne hanno permesso l'accesso non autorizzato e
        fornendo una reportistica chiara e semplice.{' '}
      </p>

      <h3>Test dell'applicazione Web (WAPT)</h3>
      <p>
        Un Web Application Penetration Test rappresenta la simulazione di un attaccante nei
        confronti di un sito, portale, gestionale, CRM, ERP, o applicazione web. Il test consiste
        nell'esame approfondito di tutte le sezioni e funzioni dell'applicazione. Una volta
        individuati dei punti di attacco validi si procede al tentativo di attacco che ha come
        obiettivo la compromissione più profonda ed estesa possibile dell'applicazione web
        aziendale.{' '}
      </p>

      <h3>Backup dei dati </h3>
      <p>
        I backup sono una pratica che aiuta le aziende a garantire che non perderanno file
        importanti. Se gli hacker violano un sistema, possono distruggere o perdere dati. Ecco
        perché un backup integro può fornire la soluzione per rimettere rapidamente in funzione la
        tua attività. Pertanto, ciò ridurrà in modo significativo il danno derivante da un attacco
        informatico. L'uso di backup di rete isolati domina il settore della sicurezza informatica
        creando una distanza di sicurezza tra le reti operative e le reti di backup.{' '}
      </p>

      <h3>Formazione dei dipendenti</h3>
      <p>
        Un metodo comune di attacco da parte degli hacker è attraverso i dipendenti di un'azienda e
        più specificamente applicando pratiche di ingegneria sociale come le e-mail di phishing. In
        molte aziende i dipendenti non sono così addestrati per evitare tali attacchi, pertanto le
        aziende dovrebbero enfatizzare questa pratica che fornirà maggiore sicurezza all'azienda.
      </p>

      <h3>Gestione delle credenziali e MFA </h3>
      <p>
        Una delle pratiche più importanti che l'azienda deve seguire e rispettare è quella delle
        credenziali di accesso ai propri sistemi. Una password complessa è molto più difficile da
        decifrare, quindi garantisce una maggiore sicurezza. L'autenticazione a più fattori (MFA)
        introduce un secondo passaggio per proteggere l'accesso ai sistemi aziendali richiedendo la
        convalida dell'identità dell'utente tramite un altro modo come SMS, e-mail, OTP, ecc.{' '}
      </p>

      <h3>Protezione degli asset digitali </h3>
      <p>
        Utilizzare su tutto il parco dei dispositivi aziendali (fissi e mobili) sistemi di analisi,
        identificazione e protezione in tempo reale degli accessi degli utenti, dello stato dei
        sistemi informatici, dei programmi in esecuzione e del loro utilizzo delle risorse
        (antivirus, workstation firewall e host-based intrusion detection/prevention system).
      </p>

      <h3>Gestione dei log e monitoraggio degli eventi di sicurezza</h3>
      <p>
        Il processo di gestione dei log permette la raccolta, l'aggregazione, l'analisi, la
        trasmissione, l'archiviazione e l'eliminazione dei dati di log, generati dai sistemi IT di
        un'azienda. Inoltre, l'utilizzo di strumenti utili alla gestione degli eventi e alla
        sicurezza delle informazioni (SIEM) consente alle organizzazioni di rilevare, analizzare e
        rispondere alle minacce di sicurezza prima che compromettano le operazioni aziendali.
      </p>
    </>
  );
};


export { BestPractices };
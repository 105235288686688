import { ExploitInfo } from '../infos/ExploitInfo';
import { ExploitTable } from '../tables/exploit/ExploitTable';
import { ReportTab } from './ReportTab';

const ExploitTab = () => (
  <ReportTab tab_id="exploits" Info={ExploitInfo} title="Possibili Attacchi">
    <ExploitTable tableId="report_tabs_tab_table_exploits" />
  </ReportTab>
);

export { ExploitTab };

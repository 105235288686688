import { ReportModal, ReportModalProps } from './ReportModal';
import React from 'react';
import { LookalikeDomainTable } from '../tables/lookalike-domain/LookalikeDomainTable';

const LookalikeDomainModal: React.FC<Omit<ReportModalProps, 'id'>> = ({ show, handleClose }) => {
  return (
    <ReportModal
      id="report_modal_lookalikeDomains"
      title="Domini Simili"
      handleClose={handleClose}
      show={show}>
      <LookalikeDomainTable tableId="fast_report_table_modal_lookalikeDomains" />
    </ReportModal>
  );
};
export { LookalikeDomainModal };

import { ExploitTable } from '../tables/exploit/ExploitTable';
import { ReportModal, ReportModalProps } from './ReportModal';
import React from 'react';

const ExploitModal: React.FC<Omit<ReportModalProps, 'id'>> = ({ show, handleClose }) => {
  return (
    <ReportModal
      id="report_modal_exploits"
      title="Possibili Attacchi"
      handleClose={handleClose}
      show={show}>
      <ExploitTable tableId="fast_report_table_modal_exploits" />
    </ReportModal>
  );
};
export { ExploitModal };

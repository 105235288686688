/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { isNil } from 'lodash';
import React, { FC, createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { WithChildren } from 'src/_metronic/helpers';
import { useAPI } from 'src/_metronic/layout/core';
import { IFAST } from 'src/_types';
import * as validator from 'validator';

export type ReporterRootLocationState = {
  domain: string;
  uuid?: string;
  pdf?: boolean;
};

const ReporterCancelContext = createContext<() => void>(() => {});
const ReporterUUIDContext = createContext<undefined | string>('');
const ReporterDomainContext = createContext<undefined | string>('');
const ReporterStateContext = createContext<IFAST.API.Scan.State>('quiet');
const ReporterErrorContext = createContext<any>(undefined);
const ReporterProgressContext = createContext<number>(0);
const ReporterPDFContext = createContext<boolean>(false);
const ReporterDataContext = createContext<undefined | IFAST.API.Report>(undefined);

const ReporterRoot: FC<WithChildren> = ({ children }) => {
  const { api } = useAPI();

  const location = useLocation();
  const navigate = useNavigate();
  const [FastData, setFastData] = useState<IFAST.API.Report>();
  const [FastError, setFastError] = useState<any>();
  const [FastUUID, setFastUUID] = useState<string>('');
  const [FastDomain, setFastDomain] = useState<string>('');
  const [FastPDF, setFastPDF] = useState<boolean>();
  const [FastState, setFastState] = useState<IFAST.API.Scan.State>('quiet');
  const [FastProgress, setFastProgress] = useState<number>(0);
  const [interval_id, setIntervalID] = useState<number | undefined>();

  const cancel = () => {
    axios
      .post<IFAST.API.Responses.Canceled>(`${api}/fast/cancel`, { uuid: FastUUID })
      .then((response) => {
        setFastState('interrupted');
      })
      .catch((error) => {
        setFastState('interrupted');
        setFastError(error);
      });
  };

  useEffect(() => {
    if (!location?.state) return navigate('/start');

    if (FastState === 'running') return;

    const location_state = location.state as ReporterRootLocationState;

    setFastPDF(location_state.pdf);

    const uuid = location_state.uuid;
    const domain = location_state.domain;
    if (!isNil(uuid) && validator.default.isUUID(uuid)) {
      setFastUUID(uuid);
    } else if (!isNil(domain) && validator.default.isFQDN(domain)) {
      axios
        .post<IFAST.API.Responses.Start>(`${api}/fast/start`, { domain })
        .then((response) => {
          setFastState('running');
          setFastProgress(0);
          setFastUUID(response.data.uuid);
          setFastDomain(response.data.domain);
        })
        .catch((error) => {
          setFastState('interrupted');
          setFastError(error);
        });
    } else {
      return navigate('/start');
    }
    return () => {
      setFastState('quiet');
      setFastProgress(0);
    };
  }, []);

  useEffect(() => {
    if (isNil(interval_id) && !isNil(FastUUID) && validator.default.isUUID(FastUUID)) {
      const interval_id = window.setInterval(() => {
        axios
          .get<IFAST.API.Responses.State>(`${api}/fast/state`, { params: { uuid: FastUUID } })
          .then((response) => {
            setFastProgress(response.data.progress);
            if (response.data.state === 'completed' || response.data.state === 'interrupted') {
              setFastProgress(100);
              setTimeout(() => setFastState(response.data.state), 2000);
              clearInterval(interval_id);
            } else {
              setFastProgress(response.data.progress);
            }
          })
          .catch((error) => {
            setFastState('interrupted');
            setFastError(error);
          });
      }, 1000);
      setIntervalID(interval_id);
    }

    return () => {
      if (!isNil(interval_id)) window.clearInterval(interval_id);
    };
  }, [FastUUID, interval_id]);

  useEffect(() => {
    if (
      !isNil(FastUUID) &&
      validator.default.isUUID(FastUUID) &&
      (FastState === 'completed' || FastState === 'interrupted')
    ) {
      axios
        .get<IFAST.API.Responses.Get>(`${api}/fast/get`, { params: { uuid: FastUUID } })
        .then((response) => {
          setFastState('completed');
          setFastProgress(100);
          setFastDomain(response.data.domain);
          setFastData(response.data.report);
        })
        .catch((error) => setFastError(error));
    }
  }, [FastUUID, FastState]);

  // if (!report) {
  //   return <Spinner animation="border" />;
  // }

  const domain = useMemo(() => FastDomain, [FastDomain]);
  const uuid = useMemo(() => FastUUID, [FastUUID]);
  const state = useMemo(() => FastState, [FastState]);
  const progress = useMemo(() => FastProgress, [FastProgress]);
  const pdf = useMemo(() => !!FastPDF, [FastPDF]);
  const error = useMemo(() => FastError, [FastError]);
  const data = useMemo(() => FastData, [FastData]);

  return (
    <ReporterCancelContext.Provider value={cancel}>
      <ReporterDomainContext.Provider value={domain}>
        <ReporterUUIDContext.Provider value={uuid}>
          <ReporterStateContext.Provider value={state}>
            <ReporterProgressContext.Provider value={progress}>
              <ReporterErrorContext.Provider value={error}>
                <ReporterPDFContext.Provider value={pdf}>
                  <ReporterDataContext.Provider value={data}>
                    {children}
                  </ReporterDataContext.Provider>
                </ReporterPDFContext.Provider>
              </ReporterErrorContext.Provider>
            </ReporterProgressContext.Provider>
          </ReporterStateContext.Provider>
        </ReporterUUIDContext.Provider>
      </ReporterDomainContext.Provider>
    </ReporterCancelContext.Provider>
  );
};

function useReporterCancel() {
  return useContext(ReporterCancelContext);
}

function useReporterDomain() {
  return useContext(ReporterDomainContext);
}

function useReporterUUID() {
  return useContext(ReporterUUIDContext);
}

function useReporterProgress() {
  return useContext(ReporterProgressContext);
}

function useReporterState() {
  return useContext(ReporterStateContext);
}

function useReporterError() {
  return useContext(ReporterErrorContext);
}

function useReporterPDF() {
  return useContext(ReporterPDFContext);
}

function useReporterData() {
  return useContext(ReporterDataContext);
}

export {
  ReporterRoot,
  useReporterCancel,
  useReporterDomain,
  useReporterUUID,
  useReporterProgress,
  useReporterState,
  useReporterError,
  useReporterPDF,
  useReporterData
};

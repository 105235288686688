import { AccountsInfo } from '../infos/BreachInfo';
import { ReportCard } from './ReportCard';
import clsx from 'clsx';
import React from 'react';
import { BreachModal } from '../modals/BreachModal';
import { useBreach } from '../providers/ReporterOSINTProvider';

type Props = {
  className: string;
};

const BreachCard: React.FC<Props> = ({ className }: Props) => {
  const breaches = useBreach();

  return (
    <ReportCard
      className={clsx(className, '')}
      counter={breaches?.length}
      color={'#ea2659'}
      bgColor={'rgba(234, 38, 89, 0.15)'}
      description={'Account Compromessi'}
      InfoContent={AccountsInfo}
      Modal={BreachModal}
    />
  );
};

export { BreachCard };

import { InfoModal } from '../components/InfoModal';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';

type Props = {
  className?: string;
  absolute?: boolean;
  size?: SizeProp;
};

const PortInfo: FC<Props> = (props) => (
  <InfoModal color={'black'} {...props} title={'Servizi Esposti'}>
    <p>
      --
    </p>
  </InfoModal>
);

export { PortInfo };

/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import { useReporterState } from '../../../providers/ReporterRoot';
import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';

const PortCell = (props: CellProps<IFAST.PartialServer, IFAST.PartialServer['port']>) => {
  const FastState = useReporterState();

  const ports = props.row.original.port;

  if (ports === undefined) {
    if (FastState === 'running') {
      return <Spinner animation="border" />;
    }
    return <span className={`badge badge-light fs-base`}>-</span>;
  }

  const color = useMemo(() => {
    if (ports.length > 0) return 'danger';
    return 'success';
  }, [ports]);

  return <span className={`badge badge-light-${color} fs-base`}>{ports.length}</span>;
};

export { PortCell };

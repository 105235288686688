import { FC } from 'react';

interface Props {
  showGoToPage?: boolean;

  pageIndex: number;
  pageCount: number;
  pageSize: number;
  pageSizes: number[];
  pageOptions: number[];
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
  setPageSize: (page: number) => void;
}

const ReportPagination: FC<Props> = ({
  showGoToPage = false,
  pageIndex,
  pageCount,
  pageSize,
  pageOptions,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  setPageSize
}) => (
  <nav
    aria-label="Page navigation example"
    className="d-flex flex-row justify-content-between align-items-center">
    <div>&nbsp;</div>
    <ul className="pagination">
      <li className="page-item">
        <button
          className="page-link"
          aria-label="First page"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}>
          &laquo;
        </button>
      </li>
      <li className="page-item">
        <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
          &lsaquo;
        </button>
      </li>
      <li className="page-item d-flex align-items-center">
        <span>
          Pagina&nbsp;
          <strong>
            {pageIndex + 1} di {pageOptions.length}
          </strong>
        </span>
      </li>
      <li className="page-item">
        <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
          &rsaquo;
        </button>
      </li>
      <li className="page-item">
        <button
          className="page-link"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}>
          &raquo;
        </button>
      </li>
    </ul>
    <div className="d-flex flex-row justify-content-center align-items-center">
      {showGoToPage ? (
        <>
          <span style={{ whiteSpace: 'nowrap' }}>Pagina: </span>
          <input
            className="form-control form-control-sm"
            type="number"
            defaultValue={pageIndex + 1}
            max={pageCount}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '50px' }}
          />
        </>
      ) : (
        <></>
      )}
      <select
        className="form-select form-select-sm"
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}>
        {[10, 25, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Mostra {pageSize}
          </option>
        ))}
      </select>
    </div>
  </nav>
);

export { ReportPagination };

import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { InfoModal } from '../reporter/normal/components/InfoModal';
import { useProfile } from './ProfileProvider';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAPI } from 'src/_metronic/layout/core';
import { LocaleDateTime } from 'src/app/components/LocalDateTime';
import { useAuth } from 'src/app/modules/auth';

interface Props {
  changeView: (view: 'settings' | 'history') => void;
  view: 'settings' | 'history';
}

const ProfileHeader: React.FC<Props> = ({ view, changeView }) => {
  const location = useLocation();
  const auth = useAuth();
  const { scans } = useProfile();
  const [maxScans, setMaxScans] = useState(0);
  const [todayScans, setTodayScans] = useState(0);
  const { api } = useAPI();

  useEffect(() => {
    axios.get<{ max: number; doneToday: number }>(`${api}/fast/availability`).then((response) => {
      setMaxScans(response.data.max);
      setTodayScans(response.data.doneToday);
    });
  }, []);

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              {auth.currentUser!.image ? (
                <img src={auth.currentUser!.image} alt="FAST" />
              ) : (
                <FontAwesomeIcon icon={faUser} size="3x" />
              )}
              <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a href="#" className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                    {auth.currentUser?.firstname} {auth.currentUser?.lastname}
                  </a>
                  {/* <a href="#">
                    <KTSVG
                      path="/media/icons/duotune/general/gen026.svg"
                      className="svg-icon-1 svg-icon-primary"
                    />
                  </a> */}
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  {/* <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                    <KTSVG
                      path="/media/icons/duotune/communication/com006.svg"
                      className="svg-icon-4 me-1"
                    />
                    Developer
                  </a>
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                    <KTSVG
                      path="/media/icons/duotune/general/gen018.svg"
                      className="svg-icon-4 me-1"
                    />
                    SF, Bay Area
                  </a> */}
                  <a
                    href="#"
                    className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                    <KTSVG
                      path="/media/icons/duotune/communication/com011.svg"
                      className="svg-icon-4 me-1"
                    />
                    {auth.currentUser?.email}
                  </a>
                </div>
              </div>
            </div>

            <div className="d-flex flex-wrap flex-stack">
              <div className="d-flex flex-column flex-grow-1 pe-8">
                <div className="d-flex flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="position-relative" style={{ right: '0px', top: '-10px' }}>
                      <InfoModal color="black" title="Scansioni odierne" absolute={true} size="1x">
                        Il numero di scansioni odierne rimanenti. Sarà sempre possibile fare ulteriori scansioni ma alcuni risultati potrebbero essere omessi.
                      </InfoModal>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {todayScans}/{maxScans}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">Scansioni odierne</div>
                  </div>

                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-2 fw-bolder">
                        {scans.length > 0 ? (
                          <LocaleDateTime date={scans[0].datetime_completed} />
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                    <div className="fw-bold fs-6 text-gray-400">Ultima scansione</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={`nav-link text-active-primary me-6 ` + (view === 'history' && 'active')}
                onClick={() => changeView('history')}
                to="#">
                Scansioni precedenti
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link text-active-primary me-6 ` + (view === 'settings' && 'active')}
                onClick={() => changeView('settings')}
                to="#">
                Impostazioni
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { ProfileHeader };

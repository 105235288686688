import { useReporterData } from './ReporterRoot';
import _ from 'lodash';
import { isNil } from 'lodash';
import { FC, createContext, useContext, useMemo } from 'react';
import { WithChildren } from 'src/_metronic/helpers';
import { IFAST } from 'src/_types';

const BreachContext = createContext<IFAST.Roll['breach']>([]);
BreachContext.displayName = 'BreachContext';

const CryptoContext = createContext<IFAST.Roll['crypto']>([]);
CryptoContext.displayName = 'CryptoContext';

const ExploitContext = createContext<IFAST.Roll['exploit']>([]);
ExploitContext.displayName = 'ExploitContext';

const EndpointContext = createContext<IFAST.Roll['endpoint']>([]);
EndpointContext.displayName = 'EndpointContext';

const LookalikeDomainContext = createContext<IFAST.Roll['lookalikeDomain']>([]);
LookalikeDomainContext.displayName = 'LookalikeDomainContext';

const PortContext = createContext<IFAST.Roll['port']>([]);
PortContext.displayName = 'PortContext';

const ProvidersContext = createContext<IFAST.Roll['provider']>([]);
ProvidersContext.displayName = 'ProvidersContext';

const ReputationContext = createContext<IFAST.Roll['reputation']>([]);
ReputationContext.displayName = 'ReputationContext';

const ResolvedContext = createContext<IFAST.Roll['resolved']>([]);
ResolvedContext.displayName = 'Resolved';

const SubdomainContext = createContext<IFAST.Roll['subdomain']>([]);
SubdomainContext.displayName = 'Subdomain';

const VulnerabilityContext = createContext<IFAST.Roll['vulnerability']>([]);
VulnerabilityContext.displayName = 'VulnerabilityContext';

const WhoisContext = createContext<IFAST.Roll['whois']>([]);
WhoisContext.displayName = 'WhoisContext';

const RollContext = createContext<Partial<IFAST.Roll>>({});
RollContext.displayName = 'RollContext';

const useCrypto = () => useContext(CryptoContext);
const useBreach = () => useContext(BreachContext);
const useExploit = () => useContext(ExploitContext);
const useEndpoint = () => useContext(EndpointContext);
const useLookalikeDomain = () => useContext(LookalikeDomainContext);
const usePort = () => useContext(PortContext);
const useProvider = () => useContext(ProvidersContext);
const useResolved = () => useContext(ResolvedContext);
const useSubdomain = () => useContext(SubdomainContext);
const useReputation = () => useContext(ReputationContext);
const useVulnerability = () => useContext(VulnerabilityContext);
const useWhois = () => useContext(WhoisContext);

const useRoll = () => useContext(RollContext);

function _roll<T extends keyof Omit<IFAST.API.ReportOfIP, 'reputation'>>(
  report: IFAST.API.Report | undefined,
  name: T
): IFAST.RollOfIP[T] {
  if (isNil(report) || isNil(report[name])) return [];

  const items: IFAST.API.ReportOfIP[T] = report![name];
  const rolled = [];
  for (const item of items) {
    const bibo_rolled = item.data.map((item2) => ({
      ip: item.ip,
      ...item2
    }));
    rolled.push(bibo_rolled);
  }

  return rolled.flat() as IFAST.RollOfIP[T];
}

const ReporterOSINTProvider: FC<WithChildren> = ({ children }) => {
  const report = useReporterData();

  const crypto = useMemo(() => _roll(report, 'crypto'), [report]);
  const breach = useMemo(
    () =>
      (report?.breach ?? []).filter(
        (item) => item.data.filter((item2) => item2.password).length > 0
      ),
    [report]
  );
  const exploit = useMemo(() => _roll(report, 'exploit'), [report]);
  const endpoint = useMemo(() => report?.endpoint ?? [], [report]);
  const lookalikeDomain = useMemo(() => report?.lookalikeDomain ?? [], [report]);
  const port = useMemo(
    () =>
      _roll(report, 'port')
        .sort((item1, item2) => item1.open_port_no - item2.open_port_no)
        .sort(
          (item1, item2) => (item2.is_vulnerability ? 1 : 0) - (item1.is_vulnerability ? 1 : 0)
        ),
    [report]
  );
  const provider = useMemo(() => _roll(report, 'provider'), [report]);
  const resolved = useMemo(() => report?.resolved ?? [], [report]);
  const subdomain = useMemo(() => report?.subdomain ?? [], [report]);
  const reputation = useMemo(
    () => report?.reputation.map((item) => ({ ip: item.ip, ...item.data })) ?? [],
    [report]
  );
  const vulnerability = useMemo(
    () =>
      _roll(report, 'vulnerability').sort(
        (item1, item2) => item2.cvssv2_score - item1.cvssv2_score
      ),
    [report]
  );
  const whois = useMemo(() => _roll(report, 'whois'), [report]);

  const roll: Partial<IFAST.Roll> = useMemo(
    () => ({
      crypto,
      breach,
      exploit,
      endpoint,
      lookalikeDomain,
      port,
      provider,
      resolved,
      subdomain,
      reputation,
      vulnerability,
      whois
    }),
    [
      crypto,
      breach,
      exploit,
      endpoint,
      lookalikeDomain,
      port,
      provider,
      resolved,
      subdomain,
      reputation,
      vulnerability,
      whois
    ]
  );

  return (
    <BreachContext.Provider value={breach}>
      <CryptoContext.Provider value={crypto}>
        <EndpointContext.Provider value={endpoint}>
          <ExploitContext.Provider value={exploit}>
            <LookalikeDomainContext.Provider value={lookalikeDomain}>
              <ReputationContext.Provider value={reputation}>
                <SubdomainContext.Provider value={subdomain}>
                  <PortContext.Provider value={port}>
                    <ProvidersContext.Provider value={provider}>
                      <VulnerabilityContext.Provider value={vulnerability}>
                        <WhoisContext.Provider value={whois}>
                          <RollContext.Provider value={roll}>{children}</RollContext.Provider>
                        </WhoisContext.Provider>
                      </VulnerabilityContext.Provider>
                    </ProvidersContext.Provider>
                  </PortContext.Provider>
                </SubdomainContext.Provider>
              </ReputationContext.Provider>
            </LookalikeDomainContext.Provider>
          </ExploitContext.Provider>
        </EndpointContext.Provider>
      </CryptoContext.Provider>
    </BreachContext.Provider>
  );
};

export {
  ReporterOSINTProvider,
  useBreach,
  useCrypto,
  useExploit,
  useEndpoint,
  useLookalikeDomain,
  usePort,
  useProvider,
  useReputation,
  useResolved,
  useSubdomain,
  useVulnerability,
  useWhois,
  useRoll
};

import { useEndpoint, useRoll } from '../../providers/ReporterOSINTProvider';
import { useReporterData } from '../../providers/ReporterRoot';
import { _Table } from '../_common/_Table';
import { columns } from './columns/_columns';
import { isNil } from 'lodash';
import { FC, useMemo } from 'react';
import { IFAST } from 'src/_types';

interface Props {
  tableId: string;
  singleEndpoint?: string;
  useGoToPage?: boolean;
}

function _make_server(
  ip: string,
  roll: Partial<IFAST.Roll>,
  report: IFAST.API.Report
): Partial<IFAST.Server> {
  const reputation = roll.reputation?.filter((item) => item && item.ip === ip);
  const isdef = (array?: any[]) => (isNil(array) || array.length > 0 ? [] : undefined);
  const udef = (array?: any[], v?: any) => (isNil(array) || array.length === 0 ? v : array);
  return {
    ip,
    domains: roll.endpoint?.filter((item) => item.ip === ip).flatMap((item) => item.domains),
    crypto: udef(
      roll.crypto?.filter((item) => item.ip === ip),
      isdef(report.crypto)
    ),
    exploit: udef(
      roll.exploit?.filter((item) => item.ip === ip),
      isdef(report.crypto)
    ),
    port: udef(
      roll.port?.filter((item) => item.ip === ip),
      isdef(report.crypto)
    ),
    provider: udef(
      roll.provider?.filter((item) => item.ip === ip),
      isdef(report.crypto)
    ),
    reputation: reputation && reputation.length > 0 ? reputation[0] : undefined,
    vulnerability: udef(
      roll.vulnerability?.filter((item) => item.ip === ip),
      isdef(report.crypto)
    ),
    whois: udef(
      roll.whois?.filter((item) => item.ip === ip),
      isdef(report.crypto)
    )
  };
}

const Table: FC<Props> = ({ tableId, singleEndpoint, useGoToPage = false }) => {
  const endpoint = useEndpoint();
  const report = useReporterData();
  const roll = useRoll();

  const data = useMemo(() => {
    const servers_set: Map<string, Partial<IFAST.Server>> = new Map();

    if (!roll) {
      return [];
    }

    const ips = Array.from(new Set(endpoint.map((item) => item.ip)));

    for (const ip of ips) {
      try {
        const server = _make_server(ip, roll, report!);
        servers_set.set(ip, server);
      } catch (error) {
        console.error(error);
      }
    }

    const servers = Array.from(servers_set.values());

    return Array.from(servers_set.values()).sort(
      (item1, item2) => (item2.vulnerability?.length ?? 0) - (item1.vulnerability?.length ?? 0)
    );
  }, [roll]);

  const noDataMessage = 'Nessun server trovato.';

  return (
    <_Table
      columns={columns}
      data={data}
      noDataMessage={noDataMessage}
      tableId={tableId}
      singleEndpoint={singleEndpoint}
    />
  );
};

export { Table as ServerTable };

/* eslint-disable react-hooks/exhaustive-deps */
import { WithChildren } from '../../helpers';
import React, { FC, createContext, useContext, useState } from 'react';

const ENDPOINTS_CONFIG_KEY = 'fast-endpoints';

const getEndpointFromLocalStorage = (): { api: string } => {
  localStorage.removeItem(ENDPOINTS_CONFIG_KEY);
  const ls = localStorage.getItem(ENDPOINTS_CONFIG_KEY);
  let api = `${process.env.REACT_APP_API_URL}/v1`;

  if (ls) {
    try {
      const endpoints = JSON.parse(ls) as { api: string };
      if (endpoints.api) {
        api = endpoints.api;
      }
    } catch (er) {
      console.error(er);
    }
  }
  return { api };
};

const setEndpointFromLocalStorage = (api: string, ws: string) => {
  try {
    localStorage.setItem(ENDPOINTS_CONFIG_KEY, JSON.stringify({ api, ws }));
  } catch (er) {
    console.error(er);
  }
};

export interface EndpointDataContextModel {
  api: string;
  setAPIEndpoint: (_api: string) => void;
}

const EndpointDataContext = createContext<EndpointDataContextModel>({
  api: getEndpointFromLocalStorage().api,
  setAPIEndpoint: (_api: string) => {},
});
/* eslint-enable no-unused-vars */

const APIProvider: FC<WithChildren> = ({ children }) => {
  const [api, setAPIEndpoint] = useState<string>(getEndpointFromLocalStorage().api);
  const value: EndpointDataContextModel = {
    api,
    setAPIEndpoint,
  };
  return <EndpointDataContext.Provider value={value}>{children}</EndpointDataContext.Provider>;
};

function useAPI() {
  return useContext(EndpointDataContext);
}

export {
  getEndpointFromLocalStorage,
  setEndpointFromLocalStorage,
  APIProvider,
  useAPI
};

import {Axios, AxiosRequestConfig} from 'axios';
import {isNil} from 'lodash';
import {IFAST} from 'src/_types';

import {AuthModel} from './_models';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';
const getAuth = (): AuthModel|undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string|null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
  }
};

export function setupAxios(axios: Axios) {
  axios.defaults.headers.common.Accept = 'application/json';
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const auth = getAuth();
    if (auth && auth.api_token) {
      config.headers!.Authorization = `Bearer ${auth.api_token}`;
    }

    if (config.url && new URL(config.url).hostname.endsWith('ngrok.io')) {
      config.headers!['ngrok-skip-browser-warning'] = 'yesbaby';
    }

    return config;
  }, (err: any) => Promise.reject(err));
}

function isTokenValid(auth_token: string): boolean {
  const parseJwt = (token: string): IFAST.API.Auth.TokenPayload|null => {
    try {
      return JSON.parse(window.atob(token.split('.')[1])) as
          IFAST.API.Auth.TokenPayload;
    } catch (e) {
      return null;
    }
  };

  const decodedJwt = parseJwt(auth_token);

  return !isNil(decodedJwt) && decodedJwt.exp * 1000 > Date.now();
}

export {getAuth, setAuth, removeAuth, isTokenValid, AUTH_LOCAL_STORAGE_KEY};

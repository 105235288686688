import { Dashboard } from './components/Dashboard';
import { AccountsTab } from './tabs/BreachTab';
import { BestPracticesTab } from './tabs/BestPracticesTab';
import { CryptoTab } from './tabs/CryptoTabs';
import { LookalikeDomainTab } from './tabs/LookalikeDomainTab';
import { EndpointTab } from './tabs/EndpointTab';
import { ExploitTab } from './tabs/ExploitTab';
import { PortTab } from './tabs/PortTab';
import { VulnerabilityTab } from './tabs/VulnerabilityTab';
import clsx from 'clsx';
import { FC } from 'react';
import { PDFButton } from './components/PDFButton';

const MenuItem: FC<{
  active?: boolean;
  title: string;
  view_id: string;
  setCurrentView: (view_id: string) => void;
}> = ({ active = false, title, view_id, setCurrentView }) => (
  <li className="nav-item">
    <a
      id={`report_toolbar_menu_link_${view_id}`}
      className={` nav-link me-6 cursor-pointer ${active ? ' active' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentView(view_id);
      }}>
      {title}
    </a>
  </li>
);

const Menu: FC<{ setCurrentView: (view_id: string) => void }> = ({ setCurrentView }) => (
  <ul
    id="report_toolbar_menu_container"
    className={clsx(
      'nav nav-stretch nav-line-tabs nav-line-tabs-2x',
      'border-transparent fs-5 fw-bolder flex-nowrap'
    )}>
    <MenuItem active title="Dashboard" view_id="dashboard" setCurrentView={setCurrentView} />
    <MenuItem title="Account compromessi" view_id="accounts" setCurrentView={setCurrentView} />
    <MenuItem title="Domini simili" view_id="lookalikeDomains" setCurrentView={setCurrentView} />
    <MenuItem title="Indirizzi IP" view_id="endpoints" setCurrentView={setCurrentView} />
    <MenuItem title="Vulnerabilità" view_id="vulnerabilities" setCurrentView={setCurrentView} />
    <MenuItem title="Attacchi" view_id="exploits" setCurrentView={setCurrentView} />
    <MenuItem title="Servizi esposti" view_id="ports" setCurrentView={setCurrentView} />
    <MenuItem title="Crittografia" view_id="crypto" setCurrentView={setCurrentView} />
    {/* <li className="nav-item color-red">
      <Link
        id="report_toolbar_menu_link_bestPractices"
        className={'nav-link me-6 fw-bolder'}
        onClick={() => setCurrentView('bestPractices')}
        to="#"
        style={{ color: 'red' }}>
        {/* <button className="btn btn-danger btn-sm btn-pulse">Best Practices</button> 
        Best Practices
      </Link>
    </li> */}
  </ul>
);

const ReportHeader = () => {
  const setCurrentView = (view: string) => {
    const links = document.querySelectorAll('#report_toolbar_menu_container > li > a');
    const tabs = document.querySelectorAll('#report_tabs > div');

    links.forEach((link) => {
      const isActiveLink = link.id === `report_toolbar_menu_link_${view}`;
      (link as HTMLElement).className = `nav-link me-6 cursor-pointer ${
        isActiveLink ? 'active' : ''
      }`;
    });
    tabs.forEach((tab) => {
      (tab as HTMLElement).style.display = tab.id === `report_tab_${view}` ? 'block' : 'none';
    });
  };

  return (
    <div
      className="w-100"
      style={{
        backgroundColor: '#f3f6f9'
      }}>
      <div className="container">
        <div className="d-flex mt-2 justify-content-between">
          <Menu setCurrentView={setCurrentView} />
          <PDFButton />
        </div>
      </div>
    </div>
  );
};

const ReportBody = () => {
  return (
    <div id="report_tabs" className="">
      <Dashboard />
      <AccountsTab />
      <LookalikeDomainTab />
      <EndpointTab />
      <VulnerabilityTab />
      <ExploitTab />
      <PortTab />
      <CryptoTab />
      <BestPracticesTab />
    </div>
  );
};

const Reporter = () => {
  return (
    <div className="container-fluid d-flex flex-column flex-column-fluid justify-content-start">
      <ReportHeader />
      <div className="container h-100 d-flex flex-column flex-start">
        <ReportBody />
      </div>
    </div>
  );
};

export { Reporter };

/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { IFAST } from 'src/_types';
import { useReporterState } from '../../../providers/ReporterRoot';

const VulnerabilityCell = (props: CellProps<IFAST.PartialServer, IFAST.PartialServer['vulnerability']>) => {
  const FastState = useReporterState();

  const vulnerabilities = props.cell.value;

  if (vulnerabilities === undefined) {
    if (FastState === 'running') {
      return <Spinner animation="border" />;
    }
    return <span className={`badge badge-light fs-base`}>-</span>;
  }

  const color = useMemo(() => {
    if (vulnerabilities.length > 7) return 'danger';
    if (vulnerabilities.length > 4) return 'warning';
    if (vulnerabilities.length > 0) return 'info';
    return 'success';
  }, [vulnerabilities]);

  return (
    <span className={`badge badge-light-${color} fs-base`}>
      {vulnerabilities.length}
    </span>
  );
};

export { VulnerabilityCell };

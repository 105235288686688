import { ScansTable } from './tables/scans/Table';
import React, { FC, useEffect } from 'react';
import axios from 'axios';

const FASTState: FC = () => {

  return (
    <div className="card mb-5 mb-xl-10 p-5">
      <div className="row g-5 g-xxl-8">
      </div>
    </div>
  );
}

export { FASTState };

import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';
import { PasswordsCell } from './PasswordsCell';


const columns: ReadonlyArray<Column<IFAST.Roll['breach'][0]>> = [
  {
    Header:"#",
    id: 'index',
    Cell: (props: CellProps<IFAST.Roll['breach'][0]>) => <span>{props.row.index + 1}</span>
  },
  {
    Header: "Email",
    id: 'email',
    accessor: 'email'
  },
  {
    Header: "Password",
    id: 'password',
    Cell: (props: CellProps<IFAST.Roll['breach'][0]>) => <PasswordsCell {...props} />,
  },
];

export { columns };

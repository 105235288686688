/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';
import { useReporterState } from '../../../providers/ReporterRoot';

const ExploitCell = (props: CellProps<IFAST.PartialServer, IFAST.PartialServer['exploit']>) => {
  const FastState = useReporterState();

  const exploits = props.cell.value;
  
  if (exploits === undefined) {
    if (FastState === 'running') {
      return <Spinner animation="border" />;
    }
    return <span className={`badge badge-light fs-base`}>-</span>;
  }

  const color = useMemo(() => {
    if (exploits.length > 0) return 'danger';
    return 'success';
  }, [exploits]);

  return (
    <span className={`badge badge-light-${color} fs-base`}>
      {exploits.length}
    </span>
  );
};

export { ExploitCell };

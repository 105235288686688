/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import { ServerReport } from '../../../components/ServerReport';
import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { IFAST } from 'src/_types';

const IPCell = (props: CellProps<IFAST.PartialServer, IFAST.PartialServer['ip']>) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = (e: any) => {
    setShowModal(true);
    e.preventDefault();
  };
  return (
    <>
      <a href="#" onClick={handleShow}>
        {props.cell.value}
      </a>
      <Modal
        className="modal modal-sticky modal-sticky-lg modal-xl modal-sticky-bottom-right"
        id="kt_modal_emails"
        backdrop={true}
        onHide={handleClose}
        aria-hidden="true"
        tabIndex="-1"
        show={showModal}
        animation={true}>
        <div className="modal-content w-100">
          <ServerReport server={props.row.original as IFAST.Server} />
        </div>
        <div className="d-flex justify-content-end">
          <button className="btn" onClick={handleClose}>
            Chiudi
          </button>
        </div>
      </Modal>
    </>
  );
};

export { IPCell };

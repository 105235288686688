import { toAbsoluteUrl } from '../../../_metronic/helpers';
import React from 'react';
import { useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { Link, Outlet } from 'react-router-dom';

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.height = '100%';
    }
    return () => {
      if (root) {
        root.style.height = 'auto';
      }
    };
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
      {/* begin::Body */}
      <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
        {/* begin::Form */}
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          {/* begin::Wrapper */}
          <div className="w-100 d-flex flex-center">
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className="d-flex flex-center flex-wrap px-5 my-10">
          {/* begin::Links */}
          <div className="w-xl-75 w-100 d-flex flex-row justify-content-around align-items-center">
            <div className="w-200px text-center">
              <img
                alt="ancharia-logo"
                className="rounded w-200px"
                src={toAbsoluteUrl('/media/fast/logos/ancharia.png')}
              />
            </div>
            <div className="w-200px text-center">
              <img
                alt="eritel-logo"
                className="rounded w-100px"
                src={toAbsoluteUrl('/media/fast/logos/eritel.png')}
              />
            </div>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/fast/login/background.png')})`
        }}>
        {/* begin::Content */}
        <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
          {/* begin::Logo */}
          <div className="mb-20">
            <img
              alt="fast-logo"
              className="rounded w-300px"
              src={toAbsoluteUrl('/media/fast/logos/fast.png')}
            />
          </div>
          {/* end::Logo */}
          {/* begin::Image */}
          <img
            className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
            src={toAbsoluteUrl('/media/fast/login/illustrazione.png')}
            alt=""
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h2 className="text-fast fs-2qx fw-bold text-center mb-7">
            First Assessment Security Tool
          </h2>
          {/* end::Title */}

          {/* begin::Text */}
          <div className="d-flex flex-column flex-center text-white fs-base fs-4 text-center">
            <div>
              FAST rappresenta uno strumento in grado di cercare evidenze pubblicamente accessibili
              e
            </div>
            <div>
              riconducibili a qualsiasi organizzazione{' '}
              <span className="text-fast fw-bolder">simulando le azioni</span> che i cyber
              attaccanti
            </div>
            <div>
              compiono durante la <span className="text-fast fw-bolder">prima fase di attacco</span>
              .
            </div>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  );
};

export { AuthLayout };

import { WithChildren } from '../../../../_metronic/helpers';
import { LayoutSplashScreen, useAPI } from '../../../../_metronic/layout/core';
import * as authHelper from './AuthHelpers';
import { AuthModel, UserModel } from './_models';
import { getUserByToken } from './_requests';
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

type AuthContextProps = {
  auth: AuthModel | undefined;
  saveAuth: (auth: AuthModel | undefined) => void;
  currentUser: UserModel | undefined;
  isSupervisor: boolean;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>;
  logout: () => void;
};

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  isSupervisor: false,
  setCurrentUser: () => {},
  logout: () => {}
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>();
  const [isSupervisor, setIsSupervisor] = useState<boolean>(false);

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth);
    if (auth) {
      authHelper.setAuth(auth);
    } else {
      authHelper.removeAuth();
      setIsSupervisor(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      setIsSupervisor(currentUser.supervisor);
      console.log(currentUser);
      
    }
  }, [currentUser])

  const logout = () => {
    saveAuth(undefined);
    setCurrentUser(undefined);
    setIsSupervisor(false);
  };

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, isSupervisor, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { api } = useAPI();

  const { auth, logout, setCurrentUser } = useAuth();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  const location = useLocation();

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(api, apiToken);
          if (data) {
            setCurrentUser(data);
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          logout();
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (auth && auth.api_token && authHelper.isTokenValid(auth.api_token)) {
      requestUser(auth.api_token);
    } else {
      logout();
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth && auth.api_token && false === authHelper.isTokenValid(auth.api_token)) {
      logout();
    }
  }, [location]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };

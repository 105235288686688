import { FC } from 'react';
import { IFAST } from 'src/_types';
import { useLookalikeDomain } from '../../providers/ReporterOSINTProvider';
import { _Table } from '../_common/_Table';
import { columns } from './columns/_columns';

interface Props {
  tableId: string;
  singleEndpoint?: string;
  useGoToPage?: boolean;
}

const Table: FC<Props> = ({ tableId, singleEndpoint, useGoToPage = false }) => {
  const data = useLookalikeDomain();

  const noDataMessage = 'Nessuna dominio simile trovato.';

  return <_Table columns={columns} data={data.map((domain) => ({ domain }))} noDataMessage={noDataMessage} tableId={tableId} singleEndpoint={singleEndpoint} />
};

export { Table as LookalikeDomainTable };

/* eslint-disable react/jsx-key */
import clsx from 'clsx';
import { Row } from 'react-table';

type Props<T extends object> = {
  row: Row<T>;
};

const CustomRow = <T extends object>({ row }: Props<T>) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell, i) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({
            'text-end min-w-100px': cell.column.id === 'actions'
          })}>
          {cell.render('Cell')}
        </td>
      );
    })}
  </tr>
);

export { CustomRow };

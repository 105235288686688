import { IFAST } from 'src/_types';
import { SCORE_VALUE } from '../ReporterScoreProvider';
import {ScoreData} from './_common';



const crypto_protocols_score = function(crypto: IFAST.Roll['crypto']):
    ScoreData {
      const value =
          () => {
            const count_tls_13 = crypto.filter((t) => t.id === 'TLS1_3').filter((item) => item.severity !== 'OK').length;

            const count_tls_11_10 = crypto.filter((t) => t.id === 'TLS1_1' || t.id === 'TLS1').filter((item) => item.severity !== 'OK').length;

            const count_sslv = crypto.filter((t) => t.id === 'SSLv2' || t.id === 'SSLv3').filter((item) => item.severity !== 'OK').length;

            if (count_tls_13 && count_tls_11_10 + count_sslv == 0) return SCORE_VALUE.low;
            if (count_sslv > 0) return SCORE_VALUE.high;
            if (count_sslv == 0 && count_tls_11_10 > 1) return SCORE_VALUE.high;
            return SCORE_VALUE.medium;
          }

      return {
        value: value(),
        name: 'Crittografia',
      };
      ;
    }



const crypto_score = function(crypto?: IFAST.Roll['crypto']):
    ScoreData[] {
      if (crypto === undefined) {
        return [
          {value: undefined, name: 'Crittografia'},
        ];
      }

      return [crypto_protocols_score(crypto)];
    }

export {crypto_protocols_score, crypto_score};
import { VulnerabilityInfo } from '../infos/VulnerabilityInfo';
import { VulnerabilityModal } from '../modals/VulnerabilityModal';
import { ReportCard } from './ReportCard';
import clsx from 'clsx';
import React from 'react';
import { useVulnerability } from '../providers/ReporterOSINTProvider';

type Props = {
  className: string;
};

const VulnerabilityCard: React.FC<Props> = ({ className }) => {
  const vulnerabilities = useVulnerability();

  return (
    <ReportCard
      className={clsx(className, '')}
      counter={vulnerabilities?.length}
      color={'#fd8d08'}
      bgColor={'rgba(253, 141, 8, 0.15)'}
      description={'Vulnerabilità Trovate'}
      InfoContent={VulnerabilityInfo}
      Modal={VulnerabilityModal}
    />
  );
};

export { VulnerabilityCard };

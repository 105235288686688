import { InputWizard } from './wizard/InputWizard';
import { SubmitWizard } from './wizard/SubmitWizard';
import { VerifyWizard } from './wizard/VerifyWizard';
import { WelcomeWizard } from './wizard/WelcomeWizard';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import { StepperComponent } from 'src/_metronic/assets/ts/components';
import { KTSVG } from 'src/_metronic/helpers';
import isFQDN from 'validator/lib/isFQDN';

interface Props {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (domain: string) => void;
}

const StartWizardPage: FC<Props> = ({ onSubmit }) => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [domain, setDomain] = useState<string>('');
  const [iframeLoaded, setIFrameLoaded] = useState(false);
  const [isSubmitButton, setSubmitButton] = useState(false);
  const [isContinueButtonEnabled, setContinueButtonEnabled] = useState(true);
  const [stepperIndex, setStepperIndex] = useState(0);

  useEffect(() => {
    let enabled = true;
    if (stepperIndex === 2) enabled = isFQDN(domain);
    setContinueButtonEnabled(enabled);
  }, [stepperIndex, iframeLoaded, domain]);

  useEffect(() => {
    if (!stepperRef.current) {
      return;
    }

    loadStepper();
  }, [stepperRef]);

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement);
  };

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }

    setSubmitButton(false);

    setStepperIndex(stepper.current.currentStepIndex - 1);
    stepper.current.goPrev();
  };

  const submitStep = () => {
    if (!stepper.current) {
      return;
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totatStepsNumber! - 1);

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext();
      setStepperIndex(stepper.current.currentStepIndex);
    } else {
      onSubmit(domain);
    }
  };

  const onDomainValid = (domain_submitted: string) => {
    setDomain(domain_submitted);
  };

  const onDomainInvalid = () => {
    setDomain('');
  };

  return (
    <div className="container-fluid bg-fast-dark d-flex w-100 py-0 py-lg-20 py-xl-20">
      <div className="container p-5">
        <div
          ref={stepperRef}
          className="h-100 stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
          id="kt_create_account_stepper">
          <div className="card card-fast-dark d-flex justify-content-center justify-content-xl-start align-items-center flex-row-auto w-100 w-xl-300px w-xxl-400px me-lg-10 mb-10 mb-lg-0">
            <div className="card-body d-flex align-items-center px-6 px-lg-10 px-xxl-15 py-20">
              <div className="stepper-nav">
                {/* begin::Step 1*/}
                <div className="stepper-item current" data-kt-stepper-element="nav">
                  {/* begin::Wrapper*/}
                  <div className="stepper-wrapper">
                    {/* begin::Icon*/}
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className="stepper-label">
                      <h3 className="stepper-title">Benvenuto!</h3>

                      <div className="stepper-desc fw-semibold">Inizio configurazione.</div>
                    </div>
                  </div>

                  <div className="stepper-line h-40px h-xl-80px"></div>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>
                    <div className="stepper-label">
                      <h3 className="stepper-title">Inserisci il dominio</h3>
                      <div className="stepper-desc fw-semibold">
                        Il dominio su cui effettuare l'analisi.
                      </div>
                    </div>
                  </div>

                  <div className="stepper-line h-40px h-xl-80px"></div>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Verifica del dominio</h3>
                      <div className="stepper-desc fw-semibold">Controlla che sia corretto.</div>
                    </div>
                  </div>
                  <div className="stepper-line h-40px h-xl-80px"></div>
                </div>

                <div className="stepper-item" data-kt-stepper-element="nav">
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">4</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Completato</h3>
                      <div className="stepper-desc fw-semibold">
                        Avvia <i>FAST!</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-fast-dark w-100">
            <div className="card-body">
              <div className="h-100 current" data-kt-stepper-element="content">
                <WelcomeWizard />
              </div>
              <div className="h-100" data-kt-stepper-element="content">
                <InputWizard
                  focus={stepperIndex === 2}
                  onValid={onDomainValid}
                  onDomainInvalid={onDomainInvalid}
                />
              </div>

              <div className="w-100 h-100" data-kt-stepper-element="content">
                <VerifyWizard domain={domain} onLoaded={() => setIFrameLoaded(true)} />
              </div>

              <div className="w-100 h-100" data-kt-stepper-element="content">
                <SubmitWizard domain={domain} startFAST={submitStep} />
              </div>
            </div>
            <div className="card-footer">
              <div className="d-flex flex-stack">
                <div className="mr-2">
                  <button
                    onClick={prevStep}
                    type="button"
                    className="btn btn-lg btn-light-primary me-3"
                    data-kt-stepper-action="previous">
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr063.svg"
                      className="svg-icon-4 me-1"
                    />
                    Indietro
                  </button>
                </div>

                {!isSubmitButton && (
                  <div>
                    <button
                      disabled={!isContinueButtonEnabled}
                      type="submit"
                      className={clsx('btn btn-lg btn-primary me-3', isSubmitButton && 'btn-pulse')}
                      onClick={() => submitStep()}>
                      <span className="indicator-label">
                        {!isSubmitButton && 'Avanti'}
                        {!isSubmitButton && (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-3 ms-2 me-0"
                          />
                        )}
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { StartWizardPage };

import { ScoreDonnut } from '../charts/ScoreDonnut';
import { SCORE_COLOR, useScore } from '../providers/ReporterScoreProvider';
import {
  faCircleQuestion,
  faInfoCircle,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { KTSVG } from 'src/_metronic/helpers';
import { IFAST } from 'src/_types';
import { BestPractices } from 'src/app/pages/reporter/normal/best_practices/BestPractices';
import { ScoreLevels } from 'src/app/pages/reporter/normal/score/description';

const FASTScoreDescription: FC<{ close: () => void }> = ({ close }) => {
  const paragraph1 =
    `Il FAST Score rappresenta il livello di esposizione al rischio cyber ` +
    `di una organizzazione, sulla base delle informazioni aziendali raccolte ` +
    `da fonti pubblicamente accessibili (OSINT).`;

  const paragraph2 =
    `L'entità e la tipologia di ` +
    `informazioni disponibili a qualsiasi criminal Hacker descrivono la ` +
    `superficie di attacco di un’organizzazione e il livello di rischio.`;

  return (
    <div className="m-auto p-5 fs-2">
      <h1>FAST Score:</h1>
      <p className="mt-5">{paragraph1}</p>
      <p>{paragraph2}</p>
      <div className="d-flex justify-content-end">
        <button className="btn" onClick={close}>
          Chiudi
        </button>
      </div>
    </div>
  );
};

const FASTScoreWhat: FC<{ close: () => void }> = ({
  close
}) => {
  const { score_level } = useScore();
  const Element = ScoreLevels[score_level];
  const _class = {
    critical: 'danger',
    high: 'warning',
    medium: 'info',
    low: 'success'
  }[score_level];
  return (
    <div className={`m-auto fs-4 alert alert-${_class}`}>
      <Element />
      <div className="d-flex justify-content-end">
        <button className="btn" onClick={close}>
          Chiudi
        </button>
      </div>
    </div>
  );
};

type Props = {
  className: string;
  color: string;
  description: string;
  textColor?: string;
};

const BestPracticeButton: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const show = (e?: any) => {
    setShowModal(true);
    e.stopPropagation();
  };
  const close = (e?: any) => {
    setShowModal(false);
    e.stopPropagation();
  };

  return (
    <>
      <button className="btn btn-sm btn-danger" onClick={show} style={{ borderRadius: '3em' }}>
        <KTSVG color="orange" path="/media/icons/duotune/general/gen003.svg" />
        <b>Consigli utili</b>
      </button>
      <Modal
        className="modal"
        id={'reporter_bp'}
        backdrop={true}
        aria-hidden="true"
        tabIndex="-1"
        show={showModal}
        animation={true}
        size="lg">
        <div className="p-10 pb-5 d-flex flex-column flex-start">
          <div className="w-100 d-flex justify-content-between align-items-start">
            <h2>{'Best Practices'}:</h2>
            <button className="btn btn-sm" onClick={close}>
              <FontAwesomeIcon
                className={'opacity-75'}
                color={'black'}
                icon={faXmark}
                size={'2x'}
              />
            </button>
          </div>
          <div className="m-auto my-5 fs-4">
            <BestPractices />
          </div>
          <div className="w-100 d-flex flex-end">
            <button className="btn btn-sm" onClick={close}>
              Chiudi
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const ScoreWhat: React.FC = () => {
  const {score_level} = useScore();
  const [showWhatModal, setShowWhatModal] = useState<boolean>(false);
  const handleWhatClose = () => setShowWhatModal(false);
  const handleWhatShow = () => setShowWhatModal(true);

  return (
    <>
      <a href="#" onClick={handleWhatShow}>
        <span style={{ fontSize: '20px' }}>
          <FontAwesomeIcon color={SCORE_COLOR[score_level]} icon={faCircleQuestion} size="1x" />
        </span>
      </a>
      <Modal
        className="modal"
        id="kt_modal_fast_score_what"
        backdrop={true}
        onHide={handleWhatClose}
        aria-hidden="true"
        tabIndex="-1"
        show={showWhatModal}
        animation={true}>
        <FASTScoreWhat close={handleWhatClose} />
      </Modal>
    </>
  );
};

const ScoreCard: React.FC<Props> = ({ className, color, description, textColor }) => {
  const { score_average, score_level } = useScore();

  const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false);
  const handleDescriptionClose = () => setShowDescriptionModal(false);
  const handleDescriptionShow = () => setShowDescriptionModal(true);

  return (
    <>
      <div className="h-100 p-2">
        <div
          className={`card card-flush cursor-pointer h-100 pt-4`}
          style={{
            backgroundColor: '#f3f6f9'
          }}>
          <div className="position-absolute" style={{ right: '15px', top: '15px' }}>
            <a onClick={handleDescriptionShow}>
              <FontAwesomeIcon style={{ color: '#848895' }} icon={faInfoCircle} size="2x" />
            </a>
            <Modal
              className="modal"
              id="kt_modal_fast_score_description"
              backdrop={true}
              onHide={handleDescriptionClose}
              aria-hidden="true"
              tabIndex="-1"
              show={showDescriptionModal}
              animation={true}>
              <FASTScoreDescription close={handleDescriptionClose} />
            </Modal>
          </div>
          <div className="card-body d-flex flex-column flex-center">
            <ScoreDonnut />
            <div className="d-flex">
              <h1 className="m-0 lh-1" style={{ color: SCORE_COLOR[score_level], fontSize: '80px' }}>
                {_.isNaN(score_average) ? '-' : (score_average).toFixed()}
              <sup><ScoreWhat/></sup>
              </h1>
            </div>
            <h1 className="my-10" style={{ color: '#595e6e' }}>
              FAST Score
            </h1>
            <BestPracticeButton />
          </div>
        </div>
      </div>
    </>
  );
};

export { ScoreCard };

import { InfoModal } from '../components/InfoModal';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';

type Props = {
  className?: string;
  absolute?: boolean;
  size?: SizeProp;
};

const ExploitInfo: FC<Props> = (props) => (
  <InfoModal color={'rgba(58, 196, 206, 0.5)'}  {...props} title={'Possibili attacchi'}>
    <p>
      Una volta identificata e classificata una vulnerabilità, è possibile verificare se esiste un
      modo per sfruttarla.
    </p>
    <p>
      Un possibile attacco (exploit) rappresenta quella procedura che permette di trarre vantaggio
      da una o più vulnerabilità.
    </p>
  </InfoModal>
);

export { ExploitInfo };

import { useAuth } from '../core/Auth';
import { AuthModel } from '../core/_models';
import { getUserByToken, login, login2 } from '../core/_requests';
import { TwoFactorAuthentication } from './TwoFactorAuthentication';
import clsx from 'clsx';
import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useAPI } from 'src/_metronic/layout/core';
import * as Yup from 'yup';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email richiesta'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password richiesta')
});

const initialValues = {
  email: '',
  password: ''
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const { api } = useAPI();
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState<string>('');
  const [token2FA, setToken2FA] = useState<string>('');
  const [authenticated, setAuthenticated] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  // eslint-disable-next-line
  let timeout: NodeJS.Timeout;

  const save = async (auth: AuthModel) => {
    saveAuth(auth);
    const { data: user } = await getUserByToken(api, auth.api_token);
    setCurrentUser(user);
  };

  const authorizating = async (code2fa: string) => {
    try {
      const { data: auth } = await login2(api, code2fa, token2FA);
      clearTimeout(timeout);
      save(auth);
    } catch (error) {
      return false;
    }
    return true;
  };

  const reset = () => {
    setAuthenticated(false);
    setLoading(false);
    setLoginError('');
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setErrors }) => {
      setLoading(true);
      try {
        const response = await login(api, values.email, values.password);
        const decoded = jwtDecode(response.data.api_token) as any;
        
        if (decoded.authenticated === true) {
          save(response.data);
        } else {
          setToken2FA(response.data.api_token);
          timeout = setTimeout(() => {
            setAuthenticated(false);
            setToken2FA('');
          }, decoded.exp - decoded.iat);
          setAuthenticated(true);
        }
      } catch (error) {
        saveAuth(undefined);
        setLoginError('Invalid credentials');
      }
      setLoading(false);
      setSubmitting(false);
    }
  });

  return (
    <div className="card rounded-3 w-100 w-xl-75">
      <div className="card-header w-100 text-center d-flex flex-center flex-column p-10">
        <h1 className="display-6 text-dark fw-bolder mb-3">Login</h1>
        <span className="text-gray-500 fw-semibold fs-6">Inserisci le tue credenziali</span>
      </div>
      <div className="card-body px-2 px-lg-20">
        <div className="h-400px d-flex flex-center">
          {!authenticated ? (
            <form
              className="form h-100 w-100 d-flex flex-column justify-content-around align-items-center"
              onSubmit={formik.handleSubmit}
              noValidate
              id="kt_login_signin_form">
              {/* begin::Form group */}
              <div className="w-75">
                <label className="form-label fs-6 fw-bolder text-dark">Email</label>
                <input
                  placeholder="Email"
                  {...formik.getFieldProps('email')}
                  className={clsx('form-control bg-transparent', {
                    'is-invalid': loginError || (formik.touched.email && formik.errors.email)
                  })}
                  type="email"
                  name="email"
                  autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group */}
              <div className="w-75">
                <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
                <input
                  type="password"
                  autoComplete="off"
                  {...formik.getFieldProps('password')}
                  className={clsx('form-control bg-transparent', {
                    'is-invalid': loginError || (formik.touched.password && formik.errors.password)
                  })}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Wrapper */}
              <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{loginError}</span>
                  </div>
                </div>
              </div>
              {/* end::Wrapper */}

              {/* begin::Action */}
              <div className="w-75 d-grid mb-10">
                <button
                  type="submit"
                  id="kt_sign_in_submit"
                  className="btn btn-primary bg-fast"
                  disabled={formik.isSubmitting || !formik.isValid}>
                  {!loading && <span className="indicator-label">Accedi</span>}
                  {loading && (
                    <span className="indicator-progress" style={{ display: 'block' }}>
                      Un attimo...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </form>
          ) : (
            <TwoFactorAuthentication onSubmit={authorizating} onBack={reset} />
          )}
        </div>
      </div>
    </div>
  );
}

import { CryptoTable } from '../tables/crypto/CryptoTable';

const CryptoTab = () => (
  <div id="report_tab_crypto" className="container" style={{ display: 'none' }}>
    <div className="d-flex fle-start my-10 ms-5">
      <h2>Crittografia</h2>
    </div>
    <div className="card">
      <div className="card-body">
        <CryptoTable tableId="report_tab_table_crypto" />
      </div>
    </div>
  </div>
);

export { CryptoTab };

import { InfoModal } from '../components/InfoModal';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';

type Props = {
  className?: string;
  absolute?: boolean;
  size?: SizeProp;
};

const AccountsInfo: FC<Props> = (props) => (
  <InfoModal color={'rgba(234, 38, 89, 0.5)'} {...props} title={'Account compromessi'}>
    <p>
      A seguito di attacchi hacker le credenziali di accesso ad un account possono essere trafugate
      e poi diffuse, o addirittura vendute. È possibile verificare se l'indirizzo mail o l'account
      di un utente è stato compromesso, e quindi correre ai ripari modificando le relative
      credenziali di accesso.
    </p>
  </InfoModal>
);

export { AccountsInfo };

import { AccountsInfo } from '../infos/BreachInfo';
import { BreachTable } from '../tables/breach/BreachTable';
import { ReportTab } from './ReportTab';

const AccountsTab = () => (
  <ReportTab tab_id="accounts" Info={AccountsInfo} title="Account compromessi">
    <BreachTable tableId="report_tabs_tab_table_accounts" />
  </ReportTab>
);

export { AccountsTab };

import { FC } from 'react';
import { columns } from 'src/app/pages/reporter/normal/tables/crypto/columns/_columns';
import { useCrypto } from '../../providers/ReporterOSINTProvider';
import { _Table } from '../_common/_Table';

interface Props {
  tableId: string;
  singleEndpoint?: string;
  useGoToPage?: boolean;
}

const Table: FC<Props> = ({ tableId, singleEndpoint, useGoToPage = false }) => {
  const cryptos = useCrypto();

  const noDataMessage = 'Nessuna informazione sul criptaggio trovata.';

  return <_Table columns={columns} data={cryptos} noDataMessage={noDataMessage} tableId={tableId} singleEndpoint={singleEndpoint} />
};

export { Table as CryptoTable };

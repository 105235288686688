import { BestPracticesCard } from '../cards/BestPracticesCard';
import { ExploitCard } from '../cards/ExploitCard';
import { ScoreCard } from '../cards/ScoreCard';
import { VulnerabilityCard } from '../cards/VulnerabilityCard';
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useReporterDomain, useReporterState } from '../providers/ReporterRoot';
import { BreachCard } from '../cards/BreachCard';
import { LookalikeDomainCard } from '../cards/LookalikeDomain';

const Dashboard = () => {
  const FastDomain = useReporterDomain();

  return (
    <div id="report_tab_dashboard" className="mt-5">
      <div className="container mt-10 d-flex flex-row flex-start align-items-center">
        <h2 className="fw-light display-6">
          Scansione per dominio:{' '}
          <b>
            <i>{FastDomain}</i>
          </b>
        </h2>
        <Link to={'/start'} style={{ color: 'rgba(25, 28, 48, 0.5)' }}>
          <span className="ms-10 pb-2 fs-2 fw-bold">
            <FontAwesomeIcon icon={faRotateRight} />
            &nbsp;nuova scansione
          </span>
        </Link>
      </div>
      <div className="row mt-10 gx-5 g-lg-20">
        <div className="col-6 d-flex justify-content-around align-items-center">
          <div className="row">
            <div className="col">
              <BreachCard className="" /> {/* mb-5 mb-xl-10 */}
            </div>
            <div className="col">
              <LookalikeDomainCard className="" /> {/* mb-5 mb-xl-10 */}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <VulnerabilityCard className="" /> {/* mb-5 mb-xl-10 */}
            </div>
            <div className="col">
              <ExploitCard className="" /> {/* mb-5 mb-xl-10 */}
            </div>
          </div>
        </div>
        <div className="col-6">
          <ScoreCard
            className=""
            color="white"
            description="FAST Livello di Pericolosità"
            textColor="text-dark"
          />
        </div>
      </div>
    </div>
  );
};

export { Dashboard };

import {
  useLookalikeDomain,
  useExploit,
  usePort,
  useVulnerability,
  useBreach
} from './ReporterOSINTProvider';
import { breach_score } from './score_functions/_breach_score';
import { exploit_score } from './score_functions/_exploit_score';
import { lookalikeDomain_score } from './score_functions/_lookalikeDomain_score';
import { port_score } from './score_functions/_port_score';
import { vulnerability_score } from './score_functions/_vulnerability_score';
import { FC, createContext, useContext, useReducer, useState, useMemo, useEffect } from 'react';
import { WithChildren } from 'src/_metronic/helpers';
import { IFAST } from 'src/_types';
import { ScoreData, ScoreValue } from 'src/app/pages/reporter/normal/providers/score_functions/_common';
import { isNil } from "lodash";

export const SCORE_VALUE: {[key in IFAST.Score.Levels]: ScoreValue } = {
  low: 100,
  medium: 75,
  high: 40,
  critical: 10
};

export const SCORE_COLOR: {[key in IFAST.Score.Levels]: string } = {
  low: '#7ebb5e',
  medium: '#f2be53',
  high: '#e88d43',
  critical: '#eb6664'
};

export const SCORE_TEXT: {[key in IFAST.Score.Levels]: string } = {
  low: 'basso',
  medium: 'medio',
  high: 'alto',
  critical: 'critico'
};

type ScoreContextProps = {
  score: ScoreData[];
  score_average: number;
  score_level: IFAST.Score.Levels;
};

const initialScore: ScoreContextProps = {
  score: [],
  score_average: 0,
  score_level: 'low',
};

const ScoreContext = createContext<ScoreContextProps>(initialScore);

const ReporterScoreProvider: FC<WithChildren> = ({ children }) => {
  // when useFast props (`value`) changes then this FC will be rerendered
  // if score or score_average changed, then all consumers of this provider will re-render
  const breaches = useBreach();
  const lookalikeDomains = useLookalikeDomain();
  const vulnerabilities = useVulnerability();
  const exploits = useExploit();
  const ports = usePort();

  const breachesScore = useMemo(() => breach_score(breaches), [breaches]);
  const lookalikeDomainsScore = useMemo(
    () => lookalikeDomain_score(lookalikeDomains),
    [lookalikeDomains]
  );

  const vulnerabilitiescore = useMemo(
    () => vulnerability_score(vulnerabilities),
    [vulnerabilities]
  );
  const exploitScore = useMemo(() => exploit_score(exploits), [exploits]);
  const portScore = useMemo(() => port_score(ports), [ports]);

  const score = useMemo(() => {
    return [
      ...breachesScore,
      ...lookalikeDomainsScore,
      // ...endpointsScore,
      ...vulnerabilitiescore,
      ...exploitScore,
      ...portScore
    ];
  }, [
    breachesScore,
    lookalikeDomainsScore,
    /*endpointsScore,*/ vulnerabilitiescore,
    exploitScore,
    portScore
  ]);

  const score_average = useMemo(() => {
    const __score__ = score.filter((s) => !isNil(s.value));
    const tot = __score__.reduce((tot, s) => s.value! + tot, 0);
    
    const average = tot / __score__.length;

    return average; //((average - 40) * 100) / 60;
  }, [score]);

  const score_level: IFAST.Score.Levels = useMemo(() => {
    let level: IFAST.Score.Levels;
    if (score_average < SCORE_VALUE.critical) {
      level = 'critical';
    } else if (score_average < SCORE_VALUE.high) {
      level = 'high';
    } else if (score_average < SCORE_VALUE.medium) {
      level = 'medium';
    } else {
      level = 'low';
    }
    return level;
  }, [score]);

  return (
    <ScoreContext.Provider key={'ScoreContext'} value={{ score, score_average, score_level }}>
      {children}
    </ScoreContext.Provider>
  );
};

const useScore = () => useContext(ScoreContext);

export { ReporterScoreProvider, useScore };

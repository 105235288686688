/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import { useReporterState } from '../../../providers/ReporterRoot';
import React, { useMemo } from 'react';
import { ProgressBar, Spinner } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { IFAST } from 'src/_types';

const ReputationCell = (
  props: CellProps<IFAST.PartialServer, IFAST.PartialServer['reputation']>
) => {
  const FastState = useReporterState();

  const reputation = props.cell.value;

  if (reputation === undefined) {
    if (FastState === 'running') {
      return <Spinner animation="border" />;
    }
    return <span className={`badge badge-light fs-base`}>-</span>;
  }

  const score = useMemo(() => {
    if (reputation.fraud_score < 20) return 10;
    return reputation.fraud_score;
  }, [reputation]);

  const color = useMemo(() => {
    if (score > 70) return 'danger';
    if (score > 30) return 'warning';
    return 'success';
  }, [score]);

  return (
    <div className="d-flex flex-row nowrap flex-center w-125px">
      <span className="text-muted fs-8">Good&nbsp;</span>
      <div className="d-inline-block rounded  w-75px">
        <ProgressBar className="bg-gray-300i " striped variant={color} now={score} />
      </div>
      <span className="text-muted fs-8">&nbsp;Bad</span>
    </div>
  );
};

export { ReputationCell };

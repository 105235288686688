import { PortInfo } from '../infos/PortInfo';
import { PortTable } from '../tables/port/PortTable';
import { ReportTab } from './ReportTab';

const PortTab = () => (
  <ReportTab tab_id="ports" Info={PortInfo} title="Servizi esposti">
    <PortTable tableId="report_tabs_tab_table_ports" />
  </ReportTab>
);

export { PortTab };

import { DetailsModalIcon } from '../components/DetailsModal';
import { ReportModalProps } from '../modals/ReportModal';
import { ScanIsRunning } from './components/ScanIsRunning';
import clsx from 'clsx';
import React from 'react';

type Props = {
  className: string;
  color: string;
  bgColor: string;
  counter?: number;
  description: string;
  InfoContent?: React.FC;
  Modal: React.FC<Omit<ReportModalProps, 'id'>>;
};

const ReportCard: React.FC<Props> = ({
  className,
  color,
  bgColor,
  counter,
  description,
  InfoContent,
  Modal
}) => {
  return (
    <>
      <div
        className={clsx(`card card-flush w-md-300px p-4 m-2`, className)}
        style={{
          color,
          backgroundColor: bgColor
        }}>
        <div
          className="card-body"
          style={{
            color
          }}>
          {InfoContent ? <InfoContent /> : []}
          {/* <DetailsModalIcon
            className={clsx(!InfoContent, 'me-5', true, 'link-pulse')}
            color={color}
            Modal={Modal}
          /> */}
          <div className="d-flex flex-row flex-nowrap justify-content-between">
            <div className="d-flex h-150px flex-column justify-content-around display-1 fw-bold lh-1">
              <div className="">{counter ?? 0}</div>
              <div className="pt-1 fw-bolder fs-1">
                { description }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ReportCard };

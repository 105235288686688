import './style.css';
import axios from 'axios';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useAPI } from 'src/_metronic/layout/core';
import validator from 'validator';

type Props = {
  focus: boolean;
  onValid: (_domain: string) => void;
  onDomainInvalid: () => void;
  onChange?: (_valid: boolean) => void;
};

const InputWizard: FC<Props> = ({ focus, onValid, onDomainInvalid, onChange }) => {
  const inputReference = useRef<HTMLInputElement>(null);
  const [bibo, setBibo] = useState<string>('Inserisci il dominio');
  const [isError, setIsError] = useState<boolean>(false);
  const [isDomainValid, setDomainIsValid] = useState<boolean>(false);
  const { api } = useAPI();

  const ANALYSIS_DNS_URL = `${api}/fast/dns`;

  useEffect(() => {
    if (focus) inputReference?.current?.focus();
  }, [focus]);

  const get_domain = (input: string) => {
    if (input.length === 0) {
      setBibo('Inserisci il dominio');
      setIsError(false);
      return false;
    } else if (input.indexOf('.') === -1 || input.lastIndexOf('.') >= input.length - 2) {
      setBibo('Inserisci il dominio');
      setIsError(false);
      return false;
    }

    let url: URL | undefined = undefined;
    try {
      url = new URL(input);
    } catch (error) {
      try {
        url = new URL(`https://${input}`);
      } catch (error) {}
    }
    return url?.hostname;
  };

  const onDomainChange = (input: string) => {
    setDomainIsValid(false);
    onChange?.(false);
    const domain = get_domain(input);
    if (domain) {
      axios.get<{ ips: string[] }>(ANALYSIS_DNS_URL, { params: { domain } }).then((response) => {
        if (response.data.ips.length > 0) {
          setBibo(`${domain} esiste`);
          setDomainIsValid(true);
          setIsError(false);
          onValid(domain);
        } else {
          setBibo(`${domain} non esiste`);
          onDomainInvalid();
          setIsError(true);
        }
      });
    }
  };

  useEffect(() => {
    const input = inputReference.current?.value;
    if (input) {
      onDomainChange(input);
    }
  }, []);

  return (
    <div className="h-100 w-100 d-flex flex-column flex-center">
      <div className="mt-20 h-100 w-100 w-xl-75 d-flex flex-column flex-center ">
        {/* <!--begin::Input group--> */}
        <input
          autoFocus
          ref={inputReference}
          id="fast_startwizard_domain_input"
          type="text"
          className="domain-input w-100 form-control"
          placeholder=""
          aria-label="dominio"
          aria-describedby="basic-addon1"
          spellCheck={false}
          onChange={(e) => onDomainChange(e.target.value)}
        />
        <div className="d-flex flex-center flex-row">
          <h1 className="domain-input-message text-muted">{bibo}</h1>&nbsp;
          {isError ? (
            <img
              src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f1416c%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f1416c%27 stroke=%27none%27/%3e%3c/svg%3e"
              alt=""
              width="25px"
              height="25px"
            />
          ) : (
            []
          )}
          {isDomainValid ? (
            <img
              src="data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230EAD63' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"
              alt=""
              width="25px"
              height="25px"
            />
          ) : (
            []
          )}
        </div>
        {/* <!--end::Input group--> */}
      </div>
    </div>
  );
};

export { InputWizard };

import React from 'react';
import { FilterProps } from 'react-table';
import { IFAST } from 'src/_types';

const EndpointSelectColumnFilter = <T extends { ip: string }>(
  props: FilterProps<T>
) => {
  const { filterValue, setFilter, preFilteredRows, id } = props.column;

  const options = React.useMemo(() => {
    const options = new Set(preFilteredRows.map((row) => row.values[id]));

    return Array.from(options);
  }, [id, preFilteredRows]);

  if (!filterValue) {
    return <></>;
  }

  // Render a multi-select box
  return (
    <select
      className="form-select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}>
      <option value="">Tutti</option>
      {options.map((option, i) => {
        return (
          <option key={`vulnerabilities_table_${id}_option_${i}`} value={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};

export { EndpointSelectColumnFilter };

import { SCORE_COLOR, SCORE_VALUE, useScore } from '../providers/ReporterScoreProvider';
import React, { useMemo } from 'react';
import GaugeChart from 'react-gauge-chart';

const ScoreDonnut: React.FC = () => {
  const { score_average, score_level } = useScore();

  return (
    <div className="w-75 position-relative">
      <div>
        <GaugeChart
          id="gauge-chart5"
          formatTextValue={(v) => {
            return v;
          }}
          textColor={SCORE_COLOR[score_level]}
          animate={false}
          arcsLength={[
            SCORE_VALUE.critical / 100,
            (SCORE_VALUE.high - SCORE_VALUE.critical) / 100,
            (SCORE_VALUE.medium - SCORE_VALUE.high) / 100,
            (100 - SCORE_VALUE.medium) / 100
          ]}
          colors={[SCORE_COLOR.critical, SCORE_COLOR.high, SCORE_COLOR.medium, SCORE_COLOR.low]}
          percent={score_average / 100}
          arcPadding={0.02}
          needleColor="#8b8d97"
          needleBaseColor="#8b8d97"
          className="w-100"
          hideText
        />
        <div className="position-absolute" style={{ left: '-10px', bottom: '25px' }}>
          <span className="fs-2 fw-bolder" style={{ color: SCORE_COLOR['critical'] }}>
            Scarso
          </span>
        </div>
        <div className="position-absolute" style={{ right: '-10px', bottom: '25px' }}>
          <span className="fs-2 fw-bolder" style={{ color: SCORE_COLOR['low'] }}>
            Ottimo
          </span>
        </div>
      </div>
    </div>
  );
};

ScoreDonnut.displayName = 'ScoreDonnut';

export { ScoreDonnut };

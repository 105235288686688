/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import { ExploitCell } from './ExploitCell';
import { IPCell } from './IPCell';
import { ReputationCell } from './ReputationCell';
import { PortCell } from './PortCell';
import { VulnerabilityCell } from './VulnerabilityCell';
import React, { useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';
import { DomainCell } from './DomainCell';
import { CryptoCell } from './CryptoCell';

const columns: ReadonlyArray<Column<IFAST.PartialServer>> = [
  {
    Header: '#',
    id: 'index',
    Cell: (props: CellProps<IFAST.PartialServer>) => (<>{props.row.index + 1}</>)
  },
  {
    Header: 'Indirizzo IP',
    accessor: (a) => a.ip,
    Cell: IPCell,
    disableFilters: true
  },
  {
    Header: 'Domini',
    accessor: 'domains',
    Cell: DomainCell,
    disableFilters: true
  },
  {
    Header: 'Reputazione',
    id: 'reputation',
    accessor: (v) => v.reputation,
    Cell: ReputationCell,
    disableFilters: true
  },
  {
    Header: 'Servizi',
    id: 'services_count',
    Cell: PortCell,
    disableFilters: true
  },
  {
    Header: 'Vulnerabilità',
    id: 'vulnerabilities_count',
    accessor: 'vulnerability',
    Cell: VulnerabilityCell,
    disableFilters: true
  },
  {
    Header: 'Possibili attacchi',
    id: 'exploits_count',
    accessor: 'exploit',
    Cell: ExploitCell,
    disableFilters: true
  },
  {
    Header: 'Crittografia',
    id: 'encryption',
    accessor: 'crypto',
    Cell: CryptoCell,
    disableFilters: true
  }
];

export { columns };

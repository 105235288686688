import { IFAST } from 'src/_types';
import { SCORE_VALUE } from '../ReporterScoreProvider';
import {ScoreData} from './_common';



const lookalikeDomain_number_score_function = function(lookalikeDomain: IFAST.Roll['lookalikeDomain']):
    ScoreData {
      const value = (() => {
        const count_lookalikeDomain = lookalikeDomain!.length;

        if (count_lookalikeDomain > 40) return SCORE_VALUE.critical;
        if (count_lookalikeDomain > 10) return SCORE_VALUE.high;
        if (count_lookalikeDomain > 1) return SCORE_VALUE.medium;
        return SCORE_VALUE.low;
      })();

      return {
        value: value,
        name: 'Rischio phishing',
      };
    }



const lookalikeDomain_score = function(lookalikeDomain?: IFAST.Roll['lookalikeDomain']):
    ScoreData[] {
      if (lookalikeDomain === undefined) {
        return [{value: undefined, name: ''}];
      }

      return [
        lookalikeDomain_number_score_function(lookalikeDomain),
      ]
    }

export {
  lookalikeDomain_number_score_function,
  lookalikeDomain_score,
};
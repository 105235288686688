import {IFAST} from 'src/_types';
import { SCORE_VALUE } from '../ReporterScoreProvider';
import {ScoreData} from './_common';



const vulnerability_number_score_function = function(
    vulnerability: IFAST.Roll['vulnerability']):
    ScoreData {
      const value =
          () => {
            const count_vulnerability = vulnerability.length;

            if (count_vulnerability > 30) return SCORE_VALUE.critical;
            if (count_vulnerability > 5) return SCORE_VALUE.high;
            if (count_vulnerability > 0) return SCORE_VALUE.medium;
            return SCORE_VALUE.low;
          }

      return {
        value: value(),
        name: 'Numero vulnerabilityerabilità totali',
      };
      ;
    }



const vulnerability_cvss_score_function = function(
    vulnerability: IFAST.Roll['vulnerability']):
    ScoreData {
      const value =
          () => {
            const count_vulnerability = vulnerability.length;
            const cvss_all = vulnerability.flatMap((item) => item.cvssv2_score);
            const sum_cvss =
                cvss_all.reduce((tot, v) => tot + v, 0);

            const avg_cvss = sum_cvss / count_vulnerability;

            if (avg_cvss >= 6) return SCORE_VALUE.critical;
            if (avg_cvss >= 2) return SCORE_VALUE.high;
            if (avg_cvss > 0) return SCORE_VALUE.medium;
            return SCORE_VALUE.low;
          }

      return {
        value: value(),
        name: 'Livello di vulnerabilityerabilità',
      };
    }


const vulnerability_cvss_high_score_function = function(
    vulnerability: IFAST.Roll['vulnerability']):
    ScoreData {
      const TH_HIGH_CVSS = 0.6;
      const value = (() => {
        const cvss_all = vulnerability.flatMap((item) => item.cvssv2_score);
        const high_cvss =
            cvss_all.filter((v) => v >= TH_HIGH_CVSS).length;

        if (high_cvss > 1) return SCORE_VALUE.critical;
        if (high_cvss == 1) return SCORE_VALUE.high;
        return undefined;
      })();

      return {
        value: value,
        name: 'Massima pericolosità',
      };
    }


const vulnerability_score = function(
    vulnerability?: IFAST.Roll['vulnerability']):
    ScoreData[] {
      if (vulnerability === undefined) {
        return [
          {
            value: undefined,
            name: 'Numero vulnerabilityerabilità totali'
          },
          {value: undefined, name: 'Livello di vulnerabilityerabilità'},
          {value: undefined, name: 'Massima pericolosità'},
        ];
      }

      return [
        vulnerability_number_score_function(vulnerability),
        vulnerability_cvss_score_function(vulnerability),
        vulnerability_cvss_high_score_function(vulnerability)
      ];
    }

export {
  vulnerability_number_score_function,
  vulnerability_cvss_score_function,
  vulnerability_cvss_high_score_function,
  vulnerability_score
};
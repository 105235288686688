/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import { CVSSCell } from './CVSSCell';
import React from 'react';
import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';
import { CVSSHeader } from './CVSSHeader';

const columns: ReadonlyArray<Column<IFAST.Roll['vulnerability'][0]>> = [
  // {
  //   Header: '#',
  //   id: 'index',
  //   Cell: (props: CellProps<IFAST.Roll['vulnerability'][0]>) => (
  //     <a
  //       href="#"
  //       onClick={(e) => {
  //         e.preventDefault();
  //       }}>
  //       {props.row.index + 1}
  //     </a>
  //   )
  // },
  {
    Header: 'CVE',
    accessor: (v) => (
      <a
        href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${v.cve_id}`}
        target="_blank"
        rel="noreferrer">
        {v.cve_id}
      </a>
    ),
    disableFilters: true
  },
  {
    Header: 'Indirizzo IP',
    accessor: (v) => v.ip,
    // Filter: (f) => <EndpointSelectColumnFilter<IFAST.Roll['vulnerability'][0]['data'][0]> {...f} />,
    // filter: 'equals'
  },
  {
    Header: CVSSHeader,
    accessor: 'cvssv2_score',
    Cell: CVSSCell,
    disableFilters: true
    // Filter: NumberRangeColumnFilter,
    // filter: 'between',
  },
  {
    Header: 'App',
    accessor: 'app_name',
    disableFilters: true
    // Filter: NumberRangeColumnFilter,
    // filter: 'between',
  },
  {
    Header: 'Attacco',
    accessor: (v) =>
      v.list_edb.length === 0
        ? '-'
        : v.list_edb.map((e, i) => (
            <a
              key={`vulnerabilities_table_${i}`}
              href={`https://www.exploit-db.com/exploits/${e.edb_id}`}
              target="_blank"
              rel="noreferrer">
              {e.edb_id}&nbsp;
            </a>
          )),
    disableFilters: true
    // Filter: NumberRangeColumnFilter,
    // filter: 'between',
  }
];

export { columns };

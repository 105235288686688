import { VulnerabilityInfo } from '../infos/VulnerabilityInfo';
import { VulnerabilityTable } from '../tables/vulnerability/VulnerabilityTable';
import { ReportTab } from './ReportTab';


const VulnerabilityTab = () => (
  <ReportTab tab_id="vulnerabilities" Info={VulnerabilityInfo} title="Vulnerabilità">
    <VulnerabilityTable tableId="report_tabs_tab_table_vulnerabilities" />
  </ReportTab>
);

export { VulnerabilityTab };

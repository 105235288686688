/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import React from 'react';
import { Column, CellProps, Renderer, FilterProps } from 'react-table';
import { IFAST } from 'src/_types';

const EndpointSelectColumnFilter: Renderer<FilterProps< IFAST.Roll['exploit'][0]>> = (props) => {
  const { filterValue, setFilter, preFilteredRows, id } = props.column;

  const options = React.useMemo(() => {
    const options = new Set(preFilteredRows.map((row) => row.values[id]));

    return Array.from(options);
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      className="form-select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}>
      <option value="">Tutti</option>
      {options.map((option, i) => {
        return (
          <option key={`exploits_table_${id}_option_${i}`} value={option}>
            {option}
          </option>
        );
      })}
    </select>
  );
};


const columns: ReadonlyArray<Column<IFAST.Roll['exploit'][0]>> = [
  {
    Header: '#',
    id: 'index',
    Cell: (props: CellProps<IFAST.Roll['exploit'][0]>) => (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
        }}>
        {props.row.index + 1}
      </a>
    )
  },
  {
    Header: 'Nome',
    accessor: 'title',
    disableFilters: true
  },
  {
    Header: 'CVE',
    accessor: 'cve_id',
    disableFilters: true
  },
  {
    Header: 'IP Address',
    accessor: (v) => v.ip,
    Filter: EndpointSelectColumnFilter,
    filter: 'equals'
  },
  {
    Header: 'Exploit ID',
    accessor: (v) => (
      <a href={`https://www.exploit-db.com/exploits/${v.edb_id}`} target="_blank" rel="noreferrer">
        {v.edb_id}
      </a>
    ),
    disableFilters: true
  }
];

export { columns };

/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosResponse } from 'axios';
import React, { FC, createContext, useContext, useState, useEffect } from 'react';
import { WithChildren } from 'src/_metronic/helpers';
import { useAPI } from 'src/_metronic/layout/core';
import { IFAST } from 'src/_types';

export interface ProfileContextModel {
  TwoFAEnabled: boolean;
  scans: IFAST.API.Responses.Olds;
  updatePassword: (oldpassword: string, newpassword: string) => Promise<void>;
  startEnable2FA: () => Promise<void>;
  endEnable2FA: (code: string) => Promise<void>;
  associate2FA: () => Promise<string>;
  test2FA: (code: string) => Promise<boolean>;
  disable2FA: () => Promise<void>;
}

const ProfileContext = createContext<ProfileContextModel>({
  TwoFAEnabled: false,
  scans: [],
  updatePassword: async (password: string) => {},
  startEnable2FA: async () => {},
  endEnable2FA: async (code: string) => {},
  associate2FA: async () => '',
  test2FA: async (code: string) => false,
  disable2FA: async () => {}
});

const ProfileProvider: FC<WithChildren> = ({ children }) => {
  const { api } = useAPI();
  const [scans, setScans] = useState<IFAST.API.Responses.Olds>([]);
  const [TwoFAEnabled, setTwoFAEnabled] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get<IFAST.API.Responses.Olds>(`${api}/fast/olds`)
      .then((response) => {
        setScans(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    axios.get<boolean>(`${api}/auth/2fa/enabled`).then((response) => {
      setTwoFAEnabled(response.data);
    });
  }, []);

  const updatePassword = async (oldpassword: string, newpassword: string) => {
    await axios.post(`${api}/auth/password/change`, { oldpassword, newpassword });
  };

  const startEnable2FA = async () => {
    await axios.post<string>(`${api}/auth/2fa/enable/start`);
    return;
  };

  const endEnable2FA = async (code: string) => {
    await axios.post<string>(`${api}/auth/2fa/enable/end`, { code2fa: code });
    setTwoFAEnabled(true);
    return;
  };

  const associate2FA = async () => {
    const response = await axios.get<string>(`${api}/auth/2fa/associate`);
    return response.data;
  };

  const test2FA = async (code: string) => {
    return (
      await axios.get<boolean>(`${api}/auth/2fa/test`, { params: { code2fa: code } })
    ).data;
  };

  const disable2FA = async () => {
    await axios.post(`${api}/auth/2fa/disable`);
    setTwoFAEnabled(false);
  };

  const value: ProfileContextModel = {
    TwoFAEnabled,
    scans,
    updatePassword,
    startEnable2FA,
    endEnable2FA,
    associate2FA,
    test2FA,
    disable2FA
  };
  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

function useProfile() {
  return useContext(ProfileContext);
}

export { ProfileProvider, useProfile };

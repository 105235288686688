import axios from 'axios';

import {AuthModel, UserModel} from './_models';

export function login(API_URL: string, email: string, password: string) {
  const LOGIN_URL = `${API_URL}/auth/login`;
  return axios.post<AuthModel>(
      LOGIN_URL,
      {username: email, password},
  );
}

export function login2(API_URL: string, code2fa: string, token: string) {
  const LOGIN2_URL = `${API_URL}/auth/login2`;
  return axios.post<AuthModel>(
      LOGIN2_URL, {code2fa}, {headers: {Authorization: `Bearer ${token}`}});
}

// Server should return AuthModel
export function register(
    API_URL: string, email: string, firstname: string, lastname: string,
    password: string, password_confirmation: string) {
  const REGISTER_URL = `${API_URL}/register`;
  return axios.post(REGISTER_URL, {
    email,
    firstname: firstname,
    lastname: lastname,
    password,
    password_confirmation
  });
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(API_URL: string, email: string) {
  const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email});
}

export function getUserByToken(API_URL: string, token: string) {
  const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/verify_token`;
  const req =
      axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {api_token: token});
  return req;
}

/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import { EndpointSelectColumnFilter } from '../../_common/EndpointSelectFilter';
import { IsVulnerableCell } from './IsVulnerableCell';
import React from 'react';
import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';

const port_app_name =
    (s: undefined|string) => {
      if (s === undefined) return '-';
      if (s === '') return '-';
      if (s === 'Unknown') return '-';
      if (s === 'N/A') return '-';
      return s;
    }

const portsColumns: ReadonlyArray<Column<IFAST.Roll['port'][0]>> = [
  {
    Header: '#',
    id: 'index',
    Cell: (props: CellProps<IFAST.Roll['port'][0]>) => (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
        }}>
        {props.row.index + 1}
      </a>
    )
  },
  {
    Header: 'Indirizzo IP',
    accessor: (v) => v.ip,
    Filter: (f) => <EndpointSelectColumnFilter<IFAST.Roll['port'][0]> {...f} />,
    filter: 'equals'
  },
  {
    Header: 'Porta',
    accessor: (p) => p.open_port_no,
    disableFilters: true
  },
  {
    Header: 'App',
    accessor: (port) => port_app_name(port.app_name),
    disableFilters: true
  },
  {
    Header: 'Versione',
    accessor: (port) => port_app_name(port.app_version),
    disableFilters: true
  },
  {
    Header: 'Stato',
    accessor: 'is_vulnerability',
    Cell: IsVulnerableCell,
    disableFilters: true
  }
];

export { portsColumns };

import { FC } from 'react';
import { usePort } from '../../providers/ReporterOSINTProvider';
import { _Table } from '../_common/_Table';
import { portsColumns } from './columns/_columns';

interface Props {
  tableId: string;
  singleEndpoint?: string;
  useGoToPage?: boolean;
}

const Table: FC<Props> = ({ tableId, singleEndpoint, useGoToPage = false }) => {
  const data = usePort();

  const noDataMessage = 'Nessun servizio trovato.';

  return <_Table columns={portsColumns} data={data} noDataMessage={noDataMessage} tableId={tableId} singleEndpoint={singleEndpoint} />
};

export { Table as PortTable };

import { IFAST } from 'src/_types';
import { SCORE_VALUE } from '../ReporterScoreProvider';
import {ScoreData} from './_common';



const exploit_number_score_function = function(exploits: IFAST.Roll['exploit']):
    ScoreData {
      const value = (() => {
        const count_exploit = exploits.length;
        if (count_exploit >= 1) return SCORE_VALUE.critical;
        return undefined;
      })();

      return {
        value: value,
        name: 'Possibili attacchi',
      };
    }



const exploit_score = function(exploits?: IFAST.Roll['exploit']):
    ScoreData[] {
      if (exploits === undefined) {
        return [{value: undefined, name: 'Possibili attacchi'}];
      }

      return [
        exploit_number_score_function(exploits),
      ]
    }

export {
  exploit_number_score_function,
  exploit_score,
};
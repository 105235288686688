import { useSupervisor } from './SupervisorProvider';
import React from 'react';
import { Link } from 'react-router-dom';
import { SupervisionMenu } from "./_common/interfaces";

interface Props {
  changeView: (view: SupervisionMenu) => void;
  view: SupervisionMenu;
}

const SupervisorHeader: React.FC<Props> = ({ view, changeView }) => {
  const { scans } = useSupervisor();

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-header">
        <div className="card-title">
          <h1>Supervisione</h1>
        </div>
      </div>
      <div className="card-body pt-9 pb-0">
        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            <li className="nav-item">
              <Link
                className={`nav-link text-active-primary me-6 ` + (view === 'history' && 'active')}
                onClick={() => changeView('history')}
                to="#">
                Scansioni precedenti
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link text-active-primary me-6 ` + (view === 'jsonschema' && 'active')}
                onClick={() => changeView('jsonschema')}
                to="#">
                JSON Schema
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link text-active-primary me-6 ` + (view === 'settings' && 'active')}
                onClick={() => changeView('settings')}
                to="#">
                Impostazioni
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { SupervisorHeader };

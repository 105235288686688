import { CryptoTable } from '../tables/crypto/CryptoTable';
import { ExploitTable } from '../tables/exploit/ExploitTable';
import { PortTable } from '../tables/port/PortTable';
import { VulnerabilityTable } from '../tables/vulnerability/VulnerabilityTable';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { IFAST } from 'src/_types';

interface Props {
  server: IFAST.Server;
}

const PortPanel: FC<{ server: IFAST.Server }> = ({ server }) => {
  return (
    <div className="d-flex flex-column gap-7 gap-lg-10">
      <div className="card card-flush py-4">
        <div className="card-header">
          <div className="card-title">
            <h2>Porte aperte</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <PortTable tableId="fast_report_table_port_panel" singleEndpoint={server.ip} />
        </div>
      </div>
    </div>
  );
};

const VulnerabilityPanel: FC<{ server: IFAST.Server }> = ({ server }) => {
  return (
    <div className="d-flex flex-column gap-7 gap-lg-10">
      <div className="card card-flush py-4">
        {/*begin::Card header*/}
        <div className="card-header">
          <div className="card-title">
            <h2>Vulnerabilità</h2>
          </div>
        </div>
        {/*end::Card header*/}
        {/*begin::Card body*/}
        <div className="card-body pt-0">
          <VulnerabilityTable
            tableId="fast_report_table_vulnerability_panel"
            singleEndpoint={server.ip}
          />
        </div>
        {/*end::Card header*/}
      </div>
    </div>
  );
};

const ExploitPanel: FC<{ server: IFAST.Server }> = ({ server }) => {
  return (
    <div className="d-flex flex-column gap-7 gap-lg-10">
      <div className="card card-flush py-4">
        <div className="card-header">
          <div className="card-title">
            <h2>Attacchi</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <ExploitTable tableId="fast_report_table_exploit_panel" singleEndpoint={server.ip} />
        </div>
      </div>
    </div>
  );
};

const CryptoPanel: FC<{ server: IFAST.Server }> = ({ server }) => {
  return (
    <div className="d-flex flex-column gap-7 gap-lg-10">
      <div className="card card-flush py-4">
        <div className="card-header">
          <div className="card-title">
            <h2>Crittografia</h2>
          </div>
        </div>
        <div className="card-body pt-0">
          <CryptoTable tableId="fast_report_table_crypto_panel" singleEndpoint={server.ip} />
        </div>
      </div>
    </div>
  );
};

const ServerReport: FC<Props> = ({ server }) => {
  const ip = server.ip;
  const fraud_score = server.reputation?.fraud_score;
  const vulnerability = server.vulnerability ?? [];
  const vulnerability_length = vulnerability.length ?? '?';
  const vulnerability_criticalRisk = vulnerability.filter((v) => v.cvssv2_score > 9).length ?? '?'
  const vulnerability_highRisk = vulnerability.filter((v) => v.cvssv2_score <= 9 && v.cvssv2_score > 4).length ?? '?'
  const vulnerability_midRisk = vulnerability.filter((v) => v.cvssv2_score <= 4).length ?? '?'
  const port = server.port?.length ?? '?';
  const exploit = server.exploit?.length ?? '?';
  const provider = server.provider?.[0]?.domain_name_full ?? '-';
  const org_country_code = server.whois?.[0]?.org_country_code;
  const org_name = server.whois?.[0]?.org_name ?? '-';
  const city = server.whois?.[0]?.city;
  const domains = server.domains?.map((h: string, i: number) => (
    <div key={`endpoint_report_${i}`}>
      {h} <br />
    </div>
  )) ?? '-';

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title d-flex flex-row justify-content-start align-items-center">
          <h1 className="display-8 fw-bolder">{ip}</h1>
          {/* <h2
            className={clsx(
              `badge badge-light-info fw-bolder mt-5`,
              endpoint.is_vulnerable ? 'text-danger' : 'text-success'
            )}>
            {endpoint.is_vulnerable ? 'Vulnerabile' : 'Non vulnerabile'}
          </h2> */}
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework">
        {/*begin::Aside column*/}
        <div className="d-flex flex-column gap-2 w-100 w-lg-300px mb-7 me-lg-10">
          <div className="card">
            <div className="card-body d-flex flex-row">
              {fraud_score ? (
                <h2
                  className={clsx(
                    `badge badge-light-info fw-bolder`
                    // `text-${endpoint.reputation.className}`
                  )}
                  style={{
                    fontSize: '20px'
                  }}>
                  {fraud_score}
                </h2>
              ) : (
                <h2 className={clsx(`badge badge-light-info`)}>?</h2>
              )}
              <h2 className="fw-bold">&nbsp;reputazione</h2>
            </div>
          </div>
          <div className="card">
            <div className="card-body d-flex flex-column justify-content-between">
              <div>
                <h2 className="fw-bold">{vulnerability_length} vulnerabilità</h2>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <span className="badge bg-danger">
                        {vulnerability_criticalRisk}
                      </span>
                    </td>
                    <th>Altissimo rischio</th>
                  </tr>
                  <tr>
                    <td>
                      <span className="badge bg-warning">
                        {vulnerability_highRisk}
                      </span>
                    </td>
                    <th>Alto rischio</th>
                  </tr>
                  <tr>
                    <td>
                      <span className="badge bg-info">
                        {vulnerability_midRisk}
                      </span>
                    </td>
                    <th>Medio rischio</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-primary d-flex flex-row justify-content-between w-100">
              <h2 className="fw-bold">{port} porte aperte</h2>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-primary d-flex flex-row justify-content-between w-100">
              <h2 className="fw-bold">{exploit} attacchi</h2>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-primary">
              <h2 className="card-title">Domini associati</h2>
              <div className="fs-5">
                {domains}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body text-primary">
              <h2 className="card-title">Host provider</h2>
              <div className="d-flex flex-column fs-5">
                <span>{provider}</span>
                <span>{org_name}</span>
                <span>
                  {org_country_code ? (
                    <img
                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${org_country_code.toUpperCase()}.svg`}
                      width="24px"
                      height="16px"
                    />
                  ) : (
                    []
                  )}
                  {city ? `, ${city}` : ''}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*end::Aside column*/}
        {/*begin::Main column*/}
        <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
          {/*begin:::Tabs*/}
          <ul
            className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2"
            role="tablist">
            {/*begin:::Tab item*/}
            <li className="nav-item" role="presentation">
              <a
                className="nav-link text-active-primary pb-4 active"
                data-bs-toggle="tab"
                href="#kt_endpoint_port"
                aria-selected="true"
                role="tab">
                Porte
              </a>
            </li>
            {/*end:::Tab item*/}
            {/*begin:::Tab item*/}
            <li className="nav-item" role="presentation">
              <a
                className="nav-link text-active-primary pb-4"
                data-bs-toggle="tab"
                href="#kt_endpoint_vulnerability"
                aria-selected="false"
                tabIndex={-1}
                role="tab">
                Vulnerabilità
              </a>
            </li>
            {/*end:::Tab item*/}
            {/*begin:::Tab item*/}
            <li className="nav-item" role="presentation">
              <a
                className="nav-link text-active-primary pb-4"
                data-bs-toggle="tab"
                href="#kt_endpoint_exploit"
                aria-selected="false"
                tabIndex={-1}
                role="tab">
                Possibili attacchi
              </a>
            </li>
            {/*end:::Tab item*/}
            {/*begin:::Tab item*/}
            <li className="nav-item" role="presentation">
              <a
                className="nav-link text-active-primary pb-4"
                data-bs-toggle="tab"
                href="#kt_endpoint_crypto"
                aria-selected="false"
                tabIndex={-1}
                role="tab">
                Crittografia
              </a>
            </li>
            {/*end:::Tab item*/}
          </ul>
          {/*end:::Tabs*/}
          {/*begin::Tab content*/}
          <div className="tab-content">
            {/*begin::Tab pane*/}
            <div className="tab-pane fade show active" id="kt_endpoint_port" role="tab-panel">
              <PortPanel server={server} />
            </div>
            {/*end::Tab pane*/}
            {/*begin::Tab pane*/}
            <div className="tab-pane fade" id="kt_endpoint_vulnerability" role="tab-panel">
              <VulnerabilityPanel server={server} />
            </div>
            {/*end::Tab pane*/}
            {/*begin::Tab pane*/}
            <div className="tab-pane fade" id="kt_endpoint_exploit" role="tab-panel">
              <ExploitPanel server={server} />
            </div>
            {/*end::Tab pane*/}
            {/*begin::Tab pane*/}
            <div className="tab-pane fade" id="kt_endpoint_crypto" role="tab-panel">
              <CryptoPanel server={server} />
            </div>
            {/*end::Tab pane*/}
          </div>
          {/*end::Tab content*/}
        </div>
        {/*end::Main column*/}
      </div>
    </div>
  );
};

export { ServerReport };

import { BestPractices } from './best_practices/BestPractices';
import { Dashboard } from './components/Dashboard';
import { ReporterOSINTProvider } from './providers/ReporterOSINTProvider';
import { ReporterRoot } from './providers/ReporterRoot';
import { ReporterScoreProvider } from './providers/ReporterScoreProvider';
import { BreachTable } from './tables/breach/BreachTable';
import { CryptoTable } from './tables/crypto/CryptoTable';
import { ExploitTable } from './tables/exploit/ExploitTable';
import { LookalikeDomainTable } from './tables/lookalike-domain/LookalikeDomainTable';
import { PortTable } from './tables/port/PortTable';
import { ServerTable } from './tables/servers/ServerTable';
import { VulnerabilityTable } from './tables/vulnerability/VulnerabilityTable';

const ReporterPDFBody = () => {
  return (
    <>
      <div className="page page-padding">
        <div className="page-content">
          <Dashboard />
        </div>
      </div>
      <div className="page page-padding">
        <div className="page-content">
          <BreachTable tableId="report_tabs_tab_table_accounts" />
        </div>
      </div>
      <div className="page page-padding">
        <div className="page-content">
          <LookalikeDomainTable tableId="report_tabs_tab_table_lookalikeDomains" />
        </div>
      </div>
      <div className="page page-padding">
        <div className="page-content">
          <ServerTable tableId="fast_report_tab_table_endpoints" />
        </div>
      </div>
      <div className="page page-padding">
        <div className="page-content">
          <VulnerabilityTable tableId="report_tabs_tab_table_vulnerabilities" />
        </div>
      </div>
      <div className="page page-padding">
        <div className="page-content">
          <ExploitTable tableId="report_tabs_tab_table_exploits" />
        </div>
      </div>
      <div className="page page-padding">
        <div className="page-content">
          <PortTable tableId="report_tabs_tab_table_ports" />
        </div>
      </div>
      <div className="page page-padding">
        <div className="page-content">
          <CryptoTable tableId="report_tab_table_crypto" />
        </div>
      </div>
      <div className="page page-padding">
        <div className="page-content">
          <BestPractices />
        </div>
      </div>
    </>
  );
};

const ReporterPDF = () => {
  return (
    <ReporterRoot>
      <ReporterOSINTProvider>
        <ReporterScoreProvider>
          <ReporterPDFBody />
        </ReporterScoreProvider>
      </ReporterOSINTProvider>
    </ReporterRoot>
  );
};

export { ReporterPDF };

import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { ProfileHeader } from './ProfileHeader';
import { ProfileProvider, useProfile } from './ProfileProvider';
import { Overview } from './Overview';
import { useEffect, useState } from 'react';
import { SignInMethod } from 'src/app/modules/accounts/components/settings/cards/SignInMethod';
import { AuthSettings } from './AuthSettings';

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/profile/overview',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
];

const ProfilePage = () => {
  const [view, changeView] = useState<'history' | 'settings'>('history');

  return (
    <div className="container">
      <ProfileHeader view={view} changeView={changeView} />
      <PageTitle breadcrumbs={profileBreadCrumbs}>Sommario</PageTitle>
      {view === 'history' ? <Overview /> : <AuthSettings />}
    </div>
  );
};

const ProfileWrapper = () => {
  return (
    <ProfileProvider>
      <ProfilePage />
    </ProfileProvider>
  );
};

export { ProfileWrapper };

import moment from 'moment';
import { FC } from 'react';
import Moment from 'react-moment';

const LocaleDateTime: FC<{ date: Date }> = ({ date }) => {
  moment.updateLocale('en', {
    weekdays: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
    months: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ]
  });

  return (
    <>
      <Moment format={'HH:mm [di] dddd DD MMMM YYYY'} date={date} local />
    </>
  );
};

export { LocaleDateTime };

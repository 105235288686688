import { ReportModal, ReportModalProps } from './ReportModal';
import React from 'react';
import { BreachTable } from '../tables/breach/BreachTable';

const BreachModal: React.FC<Omit<ReportModalProps, 'id'>> = ({ show, handleClose }) => {
  return (
    <ReportModal
      id="report_modal_accounts"
      title="Account Compromessi"
      handleClose={handleClose}
      show={show}>
      <BreachTable tableId="fast_report_table_modal_accounts" />
    </ReportModal>
  );
};
export { BreachModal };

import { useProfile } from './ProfileProvider';
import { Settings2FA } from './settings/Settings2FA';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import * as PasswordValidator from 'password-validator';
import React, { useEffect, useState } from 'react';
import PasswordChecklist from 'react-password-checklist';
import ReactPasswordChecklist from 'react-password-checklist';
import { KTSVG } from 'src/_metronic/helpers';
import { useAuth } from 'src/app/modules/auth';
import * as Yup from 'yup';

const schema = new PasswordValidator.default();
schema
  .is()
  .min(12, 'ALmeno 1 caratteri')
  .is()
  .max(100)
  .has()
  .symbols(1, 'Almeno un simbolo')
  .has()
  .uppercase(1, 'Almeno un carattere maiuscolo')
  .has()
  .lowercase(1, 'Almeno un carattere minuscolo')
  .has()
  .digits(1, 'Almeno un carattere numerico')
  .has()
  .not()
  .spaces(1, 'Spazi non ammessi')
  .is()
  .not()
  .oneOf(['Passw0rd', 'Password123']);

interface IUpdatePassword {
  currentPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}

const AuthSettings: React.FC = () => {
  const { currentUser } = useAuth();
  const { updatePassword } = useProfile();

  const [newPasswordValid, setNewPasswordValid] = useState<boolean>(false);
  const [oldPasswordValid, setOldPasswordValid] = useState<boolean>(false);

  const [showPasswordForm, setPasswordForm] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordOld, setPasswordOld] = useState('');
  const [error, setError] = useState('');

  const [loading2, setLoading2] = useState(false);

  const changePassword = async () => {
    if (newPasswordValid && oldPasswordValid) {
      setLoading2(true);
      updatePassword(passwordOld, password)
        .then(() => {
          setPasswordForm(false);
        })
        .catch((error: any) => {
          if (error.statusCode === 400) setError('Vecchia password sbagliata');
          else if (error.statusCode === 401) setError('Nuova password non valida');
        })
        .finally(() => {
          setLoading2(false);
        });
    }
  };
  useEffect(() => {
    setPasswordOld('');
    setPassword('');
    setPasswordAgain('');
    setOldPasswordValid(false);
    setNewPasswordValid(false);
  }, []);

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0 cursor-pointer"
        role="button"
        data-bs-toggle="collapse"
        data-bs-target="#kt_account_signin_method">
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Metodo di autenticazione</h3>
        </div>
      </div>

      <div id="kt_account_signin_method" className="collapse show">
        <div className="card-body border-top p-9">
          <div className="d-flex flex-wrap align-items-center">
            <div id="kt_signin_email">
              <div className="fs-6 fw-bolder mb-1">Indirizzo Email</div>
              <div className="fw-bold text-gray-600">{currentUser?.email}</div>
            </div>
          </div>

          <div className="separator separator-dashed my-6"></div>

          <div className="d-flex flex-wrap align-items-center mb-10">
            <div id="kt_signin_password" className={' ' + (showPasswordForm && 'd-none')}>
              <div className="fs-6 fw-bolder mb-1">Password</div>
              <div className="fw-bold text-gray-600">************</div>
            </div>
            <div className={'d-flex flex-column flex-center ' + (!showPasswordForm && 'd-none')}>
              <div className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}>
                <div className="form">
                  <div className="row mb-1">
                    <div className="col-lg-3 d-flex flex-column flex-start">
                      <div className="fv-row mb-0">
                        <label htmlFor="confirmpassword" className="form-label fs-6 fw-bolder mb-3">
                          Vecchia Password
                        </label>
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="password"
                          onChange={(e) => setPasswordOld(e.target.value)}
                        />
                      </div>
                      <ReactPasswordChecklist
                        rules={['minLength', 'specialChar', 'number', 'capital']}
                        minLength={12}
                        value={passwordOld}
                        messages={{
                          minLength: 'Deve contenere almeno 12 caratteri',
                          lowercase: 'Deve contenere almeno un carattere minuscolo',
                          capital: 'Deve contenere almeno un carattere maiuscolo',
                          specialChar: 'Deve contenere almeno un carattere speciale',
                          match: 'Le password devono coincidere'
                        }}
                        iconComponents={{
                          InvalidIcon: <FontAwesomeIcon icon={faXmark} color="red" />,
                          ValidIcon: <FontAwesomeIcon icon={faCheck} color="green" />
                        }}
                        onChange={(isValid) => setOldPasswordValid(isValid)}
                      />
                    </div>
                    <div className="col-lg-3">
                      <div className="fv-row mb-0">
                        <label htmlFor="confirmpassword" className="form-label fs-6 fw-bolder mb-3">
                          Nuova Password
                        </label>
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 d-flex flex-column flex-start">
                      <div className="fv-row mb-0">
                        <label htmlFor="confirmpassword" className="form-label fs-6 fw-bolder mb-3">
                          Conferma Password
                        </label>
                        <input
                          type="password"
                          className="form-control form-control-lg form-control-solid"
                          onChange={(e) => setPasswordAgain(e.target.value)}
                        />
                      </div>
                      <ReactPasswordChecklist
                        rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
                        minLength={12}
                        value={password}
                        valueAgain={passwordAgain}
                        messages={{
                          minLength: 'Deve contenere almeno 12 caratteri',
                          lowercase: 'Deve contenere almeno un carattere minuscolo',
                          capital: 'Deve contenere almeno un carattere maiuscolo',
                          specialChar: 'Deve contenere almeno un carattere speciale',
                          match: 'Le password devono coincidere'
                        }}
                        iconComponents={{
                          InvalidIcon: <FontAwesomeIcon icon={faXmark} color="red" />,
                          ValidIcon: <FontAwesomeIcon icon={faCheck} color="green" />
                        }}
                        onChange={(isValid) => setNewPasswordValid(isValid)}
                      />
                    </div>

                    <div className="col-lg-3 h-100 d-flex flex-column flex-start">
                      <div className="d-flex flex-row alig-items-end">
                        <button
                          type="submit"
                          className="btn btn-primary me-2 px-6"
                          disabled={!(newPasswordValid && newPasswordValid)}
                          onClick={changePassword}>
                          {!loading2 && 'Aggiorna Password'}
                          {loading2 && (
                            <span className="indicator-progress" style={{ display: 'block' }}>
                              Attendi...{' '}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                        <button
                          onClick={() => {
                            setPasswordForm(false);
                          }}
                          id="kt_password_cancel"
                          type="button"
                          className="btn btn-color-gray-400 btn-active-light-primary px-6">
                          Cancel
                        </button>
                      </div>
                      <span className={clsx('alert alert-danger', !error && 'd-none')}>
                        {error}
                      </span>
                    </div>

                    <div className="col-lg-4">
                      <div
                        id="kt_signin_password_button"
                        className={'ms-auto ' + (showPasswordForm && 'd-none')}>
                        <button
                          onClick={() => {
                            setPasswordForm(true);
                          }}
                          className="btn btn-light btn-active-light-primary">
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="kt_signin_password_button"
              className={'ms-auto ' + (showPasswordForm && 'd-none')}>
              <button
                onClick={() => {
                  setPasswordForm(true);
                }}
                className="btn btn-light btn-active-light-primary">
                Reset Password
              </button>
            </div>
          </div>
        </div>
        <Settings2FA />
      </div>
    </div>
  );
};

export { AuthSettings };

import React, { FC, useEffect, useRef } from 'react';

type Props = {
  domain: string;
  onLoaded: () => void;
  // eslint-disable-next-line no-unused-vars
  onError?: (error: any) => void;
};

// eslint-disable-next-line no-unused-vars
const VerifyWizard: FC<Props> = ({ domain, onLoaded, onError }) => {
  const ref = useRef(null);

  const onThisError = (error: any) => {
    onError?.(error);
  };

  useEffect(() => {
    const container = document.getElementById('_fast_verify_wizard_iframe_container');
    const iframe = document.getElementById('_fast_verify_wizard_iframe');
    iframe?.setAttribute('width', `${container?.scrollWidth}`);
    iframe?.setAttribute('height', `${container?.scrollHeight}`);
  }, []);

  return (
    <div id="_fast_verify_wizard_iframe_container" className="d-flex justify-content-center w-100">
      <iframe
        id="_fast_verify_wizard_iframe"
        className="w-100 h-100"
        ref={ref}
        src={`https://${domain}`}
        title="check-frame"
        onLoad={onThisError}
        onError={onThisError}
      />
    </div>
  );
};

export { VerifyWizard };

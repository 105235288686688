import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { SupervisorHeader } from './SupervisorHeader';
import { SupervisorProvider, useSupervisor } from './SupervisorProvider';
import { Overview } from './Overview';
import { useEffect, useState } from 'react';
import { SignInMethod } from 'src/app/modules/accounts/components/settings/cards/SignInMethod';
import { Configuration } from './Configuration';
import { JSONSchemaConfiguration } from './JSONSchemaConfiguration';
import { useAuth } from 'src/app/modules/auth';
import { Navigate } from 'react-router-dom';
import { FASTState } from './FASTState';
import { SupervisionMenu } from './_common/interfaces';

const supervisorBreadCrumbs: Array<PageLink> = [
  {
    title: 'Supervisor',
    path: '/crafted/pages/supervisor/overview',
    isSeparator: false,
    isActive: false
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false
  }
];

const SupervisorPage = () => {
  const [view, changeView] = useState<SupervisionMenu>('history');

  const { isSupervisor } = useAuth();

  if (isSupervisor === false)
    return <Navigate to='/home' />

  return (
    <div className="container">
      <SupervisorHeader view={view} changeView={changeView} />
      <PageTitle breadcrumbs={supervisorBreadCrumbs}>Sommario</PageTitle>
      { view === 'history' && <Overview /> }
      { view === 'jsonschema' && <JSONSchemaConfiguration /> }
      { view === 'settings' && <Configuration /> }
      { view === 'state' && <FASTState /> }
    </div>
  );
};

const SupervisorWrapper = () => {

  return (
    <SupervisorProvider>
      <SupervisorPage />
    </SupervisorProvider>
  );
};

export { SupervisorWrapper };

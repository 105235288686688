import { toAbsoluteUrl } from '../AssetHelpers';
import React from 'react';
import SVG from 'react-inlinesvg';

type Props = {
  className?: string;
  color?: string;
  path: string;
  svgClassName?: string;
};

const KTSVG: React.FC<Props> = ({ className = '', color, path, svgClassName = 'mh-50px' }) => {
  return (
    <span className={`svg-icon ${className}`}>
      <SVG color={color} src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  );
};

export { KTSVG };

import { useReporterCancel, useReporterDomain, useReporterUUID } from './providers/ReporterRoot';
import { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

const ReporterBar: FC<{ progress: number }> = ({ progress }) => {
  return (
    <div
      className="my-20 w-100 w-lg-75 w-xl-75 w-xxl-75 progress bg-light-success position-relative"
      style={{ height: '60px' }}>
      <div
        className="progress-bar bg-fast-light fs-7 fw-bold"
        role="progressbar"
        style={{
          width: `${progress}%`
        }}
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}></div>
      <h1 className="h-60px lh-1 d-flex flex-center position-absolute w-100">{progress.toFixed()}%</h1>
    </div>
  );
};

const ReporterProgress: FC<{ progress: number }> = ({ progress }) => {
  const FastDomain = useReporterDomain();
  const cancel = useReporterCancel();

  return (
    <div className="container-fluid w-100 d-flex flex-column flex-column-fluid flex-center bg-fast">
      <h1 className="text-white fw-normal">Scansione in corso:</h1>
      <h1 className="mb-1- display-2 text-fast fw-bolder">{ FastDomain }</h1>
      <Spinner animation="border" size="sm" />
      <ReporterBar progress={progress > 100 ? 100 : progress} />
      <h1 className="mt-20 display-6 text-white text-center fw-light lh-lg">
        <i>
          "Al 27 dicembre 2022, gli attacchi rilevati sono stati pari a 12.947:
          <br />
          più del doppio dei 5.334 dell'anno prima." - Il Sole 24 Ore
        </i>
      </h1>
      <div
        className="position-absolute w-100 w-lg-75 w-xl-75 d-flex flex-end"
        style={{ bottom: '50px' }}>
        <a href="#" onClick={cancel}>
          <h1 className="btn btn-link text-white fw-light">Interrompi</h1>
        </a>
      </div>
    </div>
  );
};

export { ReporterProgress };

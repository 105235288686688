/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import { useReporterState } from '../../../providers/ReporterRoot';
import { SCORE_VALUE } from '../../../providers/ReporterScoreProvider';
import { crypto_protocols_score } from '../../../providers/score_functions/_crypto_score';
import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { IFAST } from 'src/_types';

const CryptoCell = (props: CellProps<IFAST.PartialServer, IFAST.PartialServer['crypto']>) => {
  const crypto = props.cell.value;
  const FastState = useReporterState();

  if (crypto === undefined) {
    if (FastState === 'running') {
      return <Spinner animation="border" />;
    }
    return <span className={`badge badge-light fs-base`}>-</span>;
  }

  if (crypto.length === 0) {
    return <span>-</span>;
  }

  const score = useMemo(() => {
    const score = crypto_protocols_score(crypto);

    return score;
  }, [crypto]);

  const badgeClass = useMemo(() => {
    if (score === undefined) return '';
    if (score.value === undefined) return '';
    if (score.value >= SCORE_VALUE.critical) return 'badge-light-danger';
    if (score.value >= SCORE_VALUE.high) return 'badge-light-warning';
    if (score.value >= SCORE_VALUE.medium) return 'badge-light-info';
    return 'badge-light-primary';
  }, [score]);

  const text = useMemo(() => {
    if (score === undefined) return '';
    if (score.value === undefined) return '';
    if (score.value >= SCORE_VALUE.critical) return 'Critico';
    if (score.value >= SCORE_VALUE.high) return 'Molto Pericoloso';
    if (score.value >= SCORE_VALUE.medium) return 'Pericoloso';
    return 'OK';
  }, [score]);

  return <span className={`badge ${badgeClass} fs-base`}>{`${text}`}</span>;
};

export { CryptoCell };

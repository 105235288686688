import {IFAST} from 'src/_types';
import { SCORE_VALUE } from '../ReporterScoreProvider';
import {ScoreData} from './_common';



const ports_exposed_score_function = function(ports: IFAST.Roll['port']):
    ScoreData {
      const value = (() => {
        const count_exposed = ports.length;

        const ip_number =  new Set(ports.map((item) => item.ip)).size;

        const avg = count_exposed / ip_number;

        if (avg > 5) return SCORE_VALUE.critical;
        if (avg > 4) return SCORE_VALUE.high;
        if (avg > 2) return SCORE_VALUE.medium;
        return  SCORE_VALUE.low;
      })();

      return {
        value: value,
        name: 'Servizi esposti',
      };
      ;
    }



const port_score = function(ports?: IFAST.Roll['port']):
    ScoreData[] {
      if (ports === undefined) {
        return [{value: undefined, name: 'Servizi esposti'}];
      }

      return [
        ports_exposed_score_function(ports),
      ]
    }

export {
  ports_exposed_score_function,
  port_score,
};
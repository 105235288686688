/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';


const _status = (severity: IFAST.Roll['crypto'][0]['severity']) => {
  switch (severity) {
    case 'CRITICAL': {
      return 'Critico';
    }
    case 'HIGH': {
      return 'Alta';
    }
    case 'WARN': {
      return 'Alta';
    }
    case 'MEDIUM': {
      return 'Media';
    }
    case 'LOW': {
      return 'Bassa';
    }
    case 'OK': {
      return 'OK';
    }
    default: {
      return severity;
    }
  }
}

const _finding = (finding: string) => {
  switch (finding) {
    case 'not offered': {
      return 'Disabilitato';
    }
    case 'offered': {
      return 'Abilitato';
    }
    case 'offered (deprecated)': {
      return 'Abilitato (deprecato)';
    }
    case 'offered with final': {
      return 'Abilitato';
    }
    default: {
      return finding;
    }
  }
};

const _className = (severity: IFAST.Roll['crypto'][0]['severity']) => {
  switch (severity) {
    case 'CRITICAL': {
      return 'danger';
    }
    case 'HIGH': {
      return 'warning';
    }
    case 'WARN': {
      return 'warning';
    }
    case 'MEDIUM': {
      return 'dark';
    }
    case 'LOW': {
      return 'info';
    }
    case 'OK': {
      return 'success';
    }
    default: {
      return 'primary';
    }
  }
}

const columns: ReadonlyArray<Column<IFAST.Roll['crypto'][0]>> = [
  {
    Header: '#',
    id: 'index',
    Cell: (props: CellProps<IFAST.Roll['crypto'][0]>) => (
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
        }}>
        {props.row.index + 1}
      </a>
    )
  },
  {
    Header: 'Indirizzo IP',
    accessor: (v) => v.ip,
    disableFilters: true
  },
  {
    Header: 'Protocollo',
    accessor: (i) => i.id,
    disableFilters: true,
    width: 100,
    maxWidth: 100,
  },
  {
    Header: 'Pericolosità',
    accessor: (i) => i.severity,
    Cell: (props: CellProps<IFAST.Roll['crypto'][0]>) => {
      return <span className={`badge badge-${_className(props.row.original.severity)}`}>{_status(props.cell.value)}</span>;
    },
    disableFilters: true
  },
  {
    Header: 'Stato',
    accessor: (i) => _finding(i.finding),
    width: 100,
    maxWidth: 100,
    disableFilters: true
  },
];

export { columns };

import { reInitMenu } from '../helpers';
import {
  ActivityDrawer,
  DrawerMessenger,
  InviteUsers,
  ThemeModeProvider,
  UpgradePlan
} from '../partials';
import { ContactUs } from '../partials/modals/contact-us/ContactUs';
import { Content } from './components/content';
import { HeaderWrapperEmpty } from './components/header/HeaderWrapperEmpty';
import { ScrollTop } from './components/scroll-top';
import { APIProvider } from './core';
import { PageDataProvider } from './core';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const MasterLayout = () => {
  const location = useLocation();
  useEffect(() => {
    reInitMenu();
  }, [location.key]);

  return (
    <PageDataProvider>
      <APIProvider>
        <ThemeModeProvider>
          <div className="d-flex flex-column flex-root app-root" id="kt_app_root" style={{minHeight: '100vh'}}>
            <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
              <HeaderWrapperEmpty />

              {/* <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div> */}

              <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                {/* <Sidebar /> */}
                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                  <div id="kt_app_main2" className="d-flex flex-column flex-column-fluid">
                    {/* <ToolbarWrapper /> */}
                    <Content>
                      <Outlet />
                    </Content>
                  </div>
                  {/* <FooterWrapper /> */}
                </div>
              </div>


            </div>
          </div>

          {/* begin:: Drawers */}
          <ActivityDrawer />
          {/* <RightToolbar /> */}
          <DrawerMessenger />
          {/* end:: Drawers */}

          {/* begin:: Modals */}
          <InviteUsers />
          <UpgradePlan />
          <ContactUs />
          {/* end:: Modals */}
          <ScrollTop />
        </ThemeModeProvider>
      </APIProvider>
    </PageDataProvider>
  );
};

export { MasterLayout };

import { Reporter } from './Reporter';
import { ReporterPDF } from './ReporterPDF';
import { ReporterProgress } from './ReporterProgress';
import { useReporterData, useReporterPDF, useReporterProgress, useReporterState } from './providers/ReporterRoot';
import { FC } from 'react';

const ReporterView: FC = () => {
  const FastState = useReporterState();
  const FastProgress = useReporterProgress();
  const FastPDF = useReporterPDF();

  if (FastState === 'running' || FastState === 'quiet') return <ReporterProgress progress={FastProgress} />;

  return <Reporter />;
};

export { ReporterView };

import React, { FC } from 'react';

const WelcomeWizard: FC = () => {
  return (
    <div className="h-100 d-flex flex-column justify-content-between align-items-left fs-5">
      <h1 className="mb-5">Benvenuto!</h1>
      <p>
        FAST rappresenta uno strumento in grado di cercare evidenze pubblicamente accessibili e
        riconducibili a qualsiasi organizzazione simulando le azioni che i cyber attaccanti compiono
        durante la prima fase di un attacco.
      </p>
      <p>
        A partire dal dominio dell’organizzazione, FAST è in grado di raccogliere una serie di
        informazioni come i sottodomini e gli indirizzi IP sui quali si appoggiano alcuni servizi
        web dell’organizzazione, i servizi attivi, eventuali vulnerabilità (CVE) associate a ciascun
        indirizzo IP raccolto, il livello di gravità (CVSS) di ciascuna vulnerabilità raccolta,
        eventuali exploit pubblicamente disponibili associati alle vulnerabilità raccolte, i domini
        simili a quelli dell’organizzazione, indirizzi email ed eventuali credenziali rimaste
        vittime di data breach, configurazioni SSL/TLS.
      </p>
      <p>
        Si precisa che non viene svolta alcuna attività di Vulnerability Assessment o Penetration
        Test sugli indirizzi IP analizzati. L’analisi svolta si basa esclusivamente sulla raccolta
        di informazioni passive (OSINT) che potrebbero presentare falsi positivi e/o falsi negativi.
      </p>
      <p>Lo strumento non salva in alcun modo i risultati della scansione.</p>
      <p>
        Cliccando sul pulsante "Avanti" si dichiara di aver letto e compreso le informazioni sopra
        riportate.
      </p>
    </div>
  );
};

export { WelcomeWizard };

import { ColumnInstance } from 'react-table';

type Props<T extends object> = {
  column: ColumnInstance<T>;
};

const CustomHeaderColumn = <T extends object>({ column }: Props<T>) => {
  return (
    <>
      {column.Header && typeof column.Header === 'string' ? (
        <th
          {...(column.getSortByToggleProps
            ? column.getHeaderProps(column.getSortByToggleProps)
            : column.getHeaderProps())}>
          {column.render('Header')}
          {/* <div>{column.canFilter ? column.render('Filter') : null}</div>
          <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span> */}
        </th>
      ) : (
        column.render('Header')
      )}
    </>
  );
};

export { CustomHeaderColumn };

import { DrawerComponent } from '../../../assets/ts/components';
import { WithChildren } from '../../../helpers';
import { useLayout } from '../../core';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const Content = ({ children }: WithChildren) => {
  const { config, classes } = useLayout();
  const location = useLocation();
  useEffect(() => {
    DrawerComponent.hideAll();
  }, [location]);

  return (
    <div
      id="kt_app_content"
      className={clsx(
        'app-content d-flex flex-column-fluid',
        classes.content.join(' '),
        config?.app?.content?.class
      )}>
        {children}
    </div>
  );
};

export { Content };

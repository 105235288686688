import { useAuth } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../helpers';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const HeaderUserMenu: FC = () => {
  const { currentUser, logout, isSupervisor } = useAuth();

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true">
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            {/* <img alt="Logo" src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
            {currentUser!.image ? (
              <img src={currentUser!.image} alt="FAST" />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </div>

          <div className="d-flex flex-column">
            <div className="text-dark fw-bolder d-flex align-items-center fs-5">
              {currentUser?.firstname} {currentUser?.lastname}
              {/* <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">Pro</span> */}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5 my-1">
        <Link to="/start" className="menu-link px-5 fs-6">
          Nuova scansione
        </Link>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5 my-1">
        <Link to="/profile" className="menu-link px-5">
          Il mio profilo
        </Link>
      </div>

      {isSupervisor && (
        <div className="menu-item px-5 my-1">
          <Link to="/supervision" className="menu-link px-5">
            Supervisione
          </Link>
        </div>
      )}

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Log Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };

import { ScansTable } from './tables/scans/Table';
import Form from '@rjsf/core';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAPI } from 'src/_metronic/layout/core';

const log = (type: string) => console.log.bind(console, type);
export function JSONSchemaConfiguration() {
  const [schema, setSchema] = useState<RJSFSchema>();
  const [config, setConfig] = useState<any>();
  const [submitted, setSubmitted] = useState<string | undefined>();

  const { api } = useAPI();

  useEffect(() => {
    axios.get(`${api}/configuration/schema`).then((data: any) => setSchema(data.data));
  }, []);

  useEffect(() => {
    axios.get(`${api}/configuration/get`).then((data: any) => setConfig(data.data));
  }, []);

  if (schema === undefined) {
    return <>no schema</>;
  }

  const save = (data: any) => {
    axios
      .post(`${api}/configuration/set`, config)
      .then(() => {
        setSubmitted('saved');
      })
      .catch(() => {
        setSubmitted('error');
      });
  };

  return (
    <div className="card mb-5 mb-xl-10 p-5">
      <div className="row g-5 g-xxl-8 d-flex flex-column">
        <Form
          formData={config}
          schema={schema}
          validator={validator}
          onChange={(e) => setConfig(e.formData)}
          onSubmit={save}
          onError={log('errors')}
        />
        {submitted && submitted}
      </div>
    </div>
  );
}

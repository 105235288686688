import { CustomRow } from './CustomRow';
import { ReportPagination } from './ReportPagination';
import { FC, useMemo } from 'react';
import { ColumnInstance, Row, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { CustomHeaderColumn } from 'src/app/pages/reporter/normal/tables/_common/CustomHeaderColumn';
import { useCrypto } from '../../providers/ReporterOSINTProvider';
import { useReporterState } from '../../providers/ReporterRoot';
import { isNil } from 'lodash';
import { Column } from 'react-table';

interface Props<T extends object = {}> {
  columns: ReadonlyArray<Column<T>>,
  data?: T,
  tableId: string;
  noDataMessage: string;
  singleEndpoint?: string;
  useGoToPage?: boolean;
}

type FCTable<T extends object = {}> = FC<Props<T>>;

const _Table: FCTable<any> = ({ columns, data: data_original, tableId, singleEndpoint, noDataMessage, useGoToPage = false }) => {
  const FastState = useReporterState();

  const columns_memo = useMemo(
    () =>
      singleEndpoint ? columns.filter((col) => col.Header !== 'Indirizzo IP') : columns,
    [singleEndpoint]
  );

  const data = useMemo(() => {
    if (data_original) {
      return singleEndpoint !== undefined
        ? data_original.filter((item: any) => item.ip === singleEndpoint)
        : data_original;
    } else {
      return [];
    }
  }, [data_original]);

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable<any>(
    {
      columns: columns_memo,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      disableFilters: !!singleEndpoint
    },
    useFilters,
    useSortBy,
    usePagination
  );

  if (FastState === 'running') {
    return <div>Scansione in corso...</div>
  }

  if (data.length === 0) {
    return <div>{ noDataMessage }</div>
  }

  return (
    <div className="table-responsive">
      <table
        id={tableId}
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        {...getTableProps()}>
        <thead>
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            {headers.map((column: ColumnInstance<any>) => (
              <CustomHeaderColumn<any> key={column.id} column={column} />
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row: Row<any>, i) => {
              prepareRow(row);
              return <CustomRow<any> row={row} key={`row-${i}-${row.id}`} />;
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <div className="d-flex text-center w-100 align-content-center justify-content-center">
                  Nessuna informazione trovata
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ReportPagination
        showGoToPage={false}
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
        pageOptions={pageOptions}
        pageSizes={[10, 25, 50]}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export { _Table };

import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';

const columns: ReadonlyArray<Column<{ domain: IFAST.Roll['lookalikeDomain'][0] }>> = [
  {
    Header: '#',
    id: 'index',
    Cell: (props: CellProps<{ domain: IFAST.Roll['lookalikeDomain'][0] }, number>) => <span>{props.row.index + 1}</span>
  },
  {
    Header: 'Domini simili',
    accessor: 'domain'
  }
];

export { columns };

import { ReputationInfo } from '../infos/ReputationInfo';
import { ServerTable } from '../tables/servers/ServerTable';
import { ReportTab } from './ReportTab';

const EndpointTab = () => (
  <ReportTab tab_id="endpoints" Info={ReputationInfo} title="Indirizzi IP">
    <ServerTable tableId="fast_report_tab_table_endpoints" />
  </ReportTab>
);

export { EndpointTab };

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FC, PropsWithChildren } from 'react';

type PropsInfo = {
  className?: string;
  color?: string;
  absolute?: boolean;
  size?: SizeProp;
};

type Props = {
  title: string;
  tab_id: string;
  Info: FC<PropsInfo>;
};

const ReportTab: FC<PropsWithChildren<Props>> = ({ title, tab_id, Info, children }) => (

  <div id={`report_tab_${tab_id}`} className="container" style={{ display: 'none' }}>
    <div className="d-flex fle-start my-10 ms-5">
      <h2>{title}</h2>
    </div>
    <div className="card">
      <div className="card-body">
        { children }
      </div>
    </div>
  </div>
);

export { ReportTab };

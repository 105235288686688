import { Reporter } from './Reporter';
import { ReporterProgress } from './ReporterProgress';
import { ReporterOSINTProvider } from './providers/ReporterOSINTProvider';
import { ReporterRoot, useReporterProgress, useReporterState } from './providers/ReporterRoot';
import { ReporterScoreProvider } from './providers/ReporterScoreProvider';
import { FC, useEffect } from 'react';
import { usePageData } from 'src/_metronic/layout/core';
import { ReporterView } from './ReporterView';

const ReporterWrapper: FC = () => {
  const { setPageTitle } = usePageData();

  useEffect(() => {
    setPageTitle('FAST!');
  }, []);

  /**
   * Inside `ReporterStateProvider` is checked the domain via location.state
   */

  return (
    <ReporterRoot>
      <ReporterOSINTProvider>
        <ReporterScoreProvider>
          <ReporterView />
        </ReporterScoreProvider>
      </ReporterOSINTProvider>
    </ReporterRoot>
  );
};

export { ReporterWrapper };

import { AuthLayout } from './AuthLayout';
import { Login } from './components/Login';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';


const RedrictToAuth = () => {
  return <Navigate to="/auth/login" />;
};

const AuthPage = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {/* <Route path="login" element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route path="forgot-password" element={<ForgotPassword />} /> */}
        <Route path='login/' element={<Login />} />
        <Route path='/*' element={<RedrictToAuth />} />
      </Route>
    </Routes>
  );
};

export { AuthPage };

import { LookalikeDomainInfo } from '../infos/LookalikeDomainInfo';
import { LookalikeDomainTable } from '../tables/lookalike-domain/LookalikeDomainTable';
import { ReportTab } from './ReportTab';

const LookalikeDomainTab = () => (
  <ReportTab tab_id="lookalikeDomains" Info={LookalikeDomainInfo} title="Domini simili">
    <LookalikeDomainTable tableId="report_tabs_tab_table_lookalikeDomains" />
  </ReportTab>
);

export { LookalikeDomainTab };

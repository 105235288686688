import clsx from 'clsx';
import React, { FC } from 'react';
import { KTSVG } from 'src/_metronic/helpers';

type Props = {
  domain: string;
  startFAST: () => void;
};

const SubmitWizard: FC<Props> = ({ domain, startFAST }) => {
  return (
    <div className="w-100 d-flex flex-column flex-center justify-content-start pt-20">
      <h1 className="mt-10 display-5 fw-bold">
        <i>FAST</i> è pronto per analizzare:
      </h1>
      {/* <h1 className="display-5 ps-20 mb-10">sul dominio:</h1> */}
      <h1 className={clsx(domain.length < 15 && "display-1", domain.length >= 15 && "display-6")}>
        <KTSVG
          path="/media/icons/duotune/general/gen057.svg"
          className="svg-icon-5tx svg-icon-dark ms-2 me-0"
        />
        <b>{domain}</b>
      </h1>
      <div className='mt-20'>
        <button
          type="submit"
          className="btn btn-lg btn-primary me-3 btn-pulse"
          onClick={() => startFAST()}>
          <span className="display-4 px-10">
            Avvia!
            {/* <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-3 ms-2 me-0" /> */}
          </span>
        </button>
      </div>
    </div>
  );
};

export { SubmitWizard };

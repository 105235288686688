import { StartWizardPage } from './StartWizardPage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePageData } from 'src/_metronic/layout/core';

export function StartPage() {
  const { setPageTitle, setPageDescription } = usePageData();
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle('Start FAST');
    setPageDescription('Segui la procedura guidata per iniziare');
  }, []);

  const onStart = (domain: string) => {
    navigate(`/report`, {
      state: {
        domain,
      }
    });
  };

  return <StartWizardPage onSubmit={onStart} />;
}

import { InfoModal } from '../../../components/InfoModal';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { FC, PropsWithChildren, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { HeaderProps } from 'react-table';
import { IFAST } from 'src/_types';

const CVSSHeader: FC<HeaderProps<IFAST.Roll['vulnerability'][0]>> = (props) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <th {...props.column.getHeaderProps()} style={{ cursor: 'pointer' }}>
      CVSSV22
      <a href="#" onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </a>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>CVSSV2 Score</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ogni vulnerabilità presenta dei parametri di classificazione; uno dei più importanti è il
          CVSS ovvero il Common Vulnerability Scoring System, uno standard utilizzato per
          quantificare l’effettivo rischio di una vulnerabilità informatica tramite un punteggio che
          va da 0 (rischio nullo) a 10 (rischio critico). Questa metrica fornisce informazioni
          relative al grado di complessità ed alle competenze necessarie affinché un attaccante
          possa effettivamente procedere a creare o ad utilizzare un effettivo exploit.
        </Modal.Body>
      </Modal>
    </th>
  );
};

export { CVSSHeader };

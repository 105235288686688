import { useProfile } from '../ProfileProvider';
import clsx from 'clsx';
import { isNil } from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { Button, Modal } from 'react-bootstrap';
import { KTSVG } from 'src/_metronic/helpers';

const Enabling2FAModal: FC<{
  close: () => void;
  show: boolean;
}> = ({ close, show }) => {
  const { startEnable2FA, endEnable2FA, associate2FA, test2FA } = useProfile();

  const [stepper, setStepper] = useState<number>(0);
  const [nextEnabled, setNextEnabled] = useState<boolean>(true);
  const [invalid, setInvalid] = useState<boolean>(true);
  const [errorQR, setQRError] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [qrcode, setQRCode] = useState<string>('');

  const authcodeRef = useRef<any>();

  useEffect(() => {
    switch (stepper) {
      case 0: {
        setNextEnabled(true);
        break;
      }
      case 1: {
        authcodeRef.current?.focus();
        setNextEnabled(false);
        break;
      }
      case 2: {
        setNextEnabled(true);
        break;
      }
      default: {
        setNextEnabled(true);
        break;
      }
    }
  }, [stepper]);

  const prevStep = () => {
    if (stepper > 0) setStepper((stepper) => stepper - 1);
  };

  const nextStep = () => {
    if (stepper < 2) setStepper((stepper) => stepper + 1);
    else close();
  };

  const start = () => {
    setStepper(0);
    setQRError('');
    setError('');
    setNextEnabled(false);
    setQRCode('');
    authcodeRef.current?.clear();
    startEnable2FA()
      .then(() => {
        associate2FA().then((qrcode) => {
          setNextEnabled(true);
          setQRCode(qrcode);
        });
      })
      .catch((error) => {
        setNextEnabled(false);
        setQRError('Qualcosa è andato storto')
      });
  };

  const onChange = (code: string) => {
    setInvalid(false);
    if (/\d{6}/.exec(code)) {
      endEnable2FA(code).then(() => {
        setStepper(2);
      })
      .catch((error) => {
        authcodeRef.current?.clear();
        if (error.statusCode === 406) {
          setError('Qualcosa è andato storto!');
        } else
        if (error.statusCode === 400) {
          setError('Codice non valido');
        } 
      });
    }
  };

  return (
    <Modal show={show} size={'lg'} onShow={start}>
      <Modal.Header onHide={close} closeButton>
        <Modal.Title>Abilita 2FA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid">
          <div className="card w-100">
            <div className="card-body d-flex flex-row">
              <div className="w-min-200px stepper-nav">
                <div className={`stepper-item ${stepper === 0 ? 'current' : ''}`}>
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">1</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Associa Google Authenticator</h3>

                      <div className="stepper-desc fw-semibold">Inquadra QR Code</div>
                    </div>
                  </div>

                  <div className="stepper-line h-40px"></div>
                </div>

                <div className={`stepper-item ${stepper === 1 ? 'current' : ''}`}>
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">2</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Verifica</h3>
                      <div className="stepper-desc fw-semibold">Inserisci il codice 2FA</div>
                    </div>
                  </div>

                  <div className="stepper-line h-40px"></div>
                </div>

                <div className={`stepper-item ${stepper === 2 ? 'current' : ''}`}>
                  <div className="stepper-wrapper">
                    <div className="stepper-icon w-40px h-40px">
                      <i className="stepper-check fas fa-check"></i>
                      <span className="stepper-number">3</span>
                    </div>

                    <div className="stepper-label">
                      <h3 className="stepper-title">Completato!</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-100 d-flex flex-center">
                <div className={`${stepper === 0 ? '' : 'd-none'}`}>
                  <div className="d-flex flex-center">
                    <img src={qrcode} />
                    <span
                      className={clsx(
                        'alert alert-danger',
                        errorQR.length === 0 && 'd-none'
                      )}>
                      {errorQR}
                    </span>
                  </div>
                </div>

                <div className={`${stepper === 1 ? '' : 'd-none'}`}>
                  <div className="d-flex flex-column flex-center">
                    <AuthCode
                      containerClassName="d-flex flex-wrap flex-center mb-5"
                      inputClassName="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                      allowedCharacters="numeric"
                      onChange={onChange}
                      ref={authcodeRef}
                    />
                    <span
                      className={clsx(
                        'alert alert-danger',
                        !(error) && 'd-none'
                      )}>
                      Codice non valido
                    </span>
                  </div>
                </div>

                <div className={`${stepper === 2 ? '' : 'd-none'}`}>
                  <h1>Configurazione complatata</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-stack pt-10">
          <div className="mr-2">
            <button
              onClick={prevStep}
              type="button"
              disabled={stepper === 0}
              className={`btn btn-lg btn-light-primary me-3`}>
              <KTSVG path="/media/icons/duotune/arrows/arr063.svg" className="svg-icon-4 me-1" />
              Indietro
            </button>
          </div>

          <div>
            <button
              onClick={nextStep}
              type="submit"
              className="btn btn-lg btn-primary me-3"
              disabled={!nextEnabled}>
              <span className="indicator-label">
                {stepper === 0 && 'Avanti'}
                {stepper === 1 && 'Inserisci il codice'}
                {stepper === 2 && 'Chiudi'}
                {stepper === 0 && (
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr064.svg"
                    className="svg-icon-3 ms-2 me-0"
                  />
                )}
              </span>
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const Associate2FAModal: FC<{
  close: () => void;
  show: boolean;
}> = ({ close, show }) => {
  const { associate2FA, test2FA } = useProfile();

  const [testResult, setTestResult] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<any>(false);
  const [qrcode, setQRCode] = useState<string>('');

  const onChange = (code: string) => {
    setError(undefined);
    setTestResult(undefined);
    if (/\d{6}/.exec(code)) {
      test2FA(code)
        .then((valid) => {
          setTestResult(valid);
        })
        .catch(() => setError('Qualcosa è andato storto'));
    }
  };

  const start = () => {
    setTestResult(undefined);
    setError(undefined);
    setQRCode('');
    associate2FA().then(setQRCode);
  };

  return (
    <Modal show={show} size={'lg'} onShow={start}>
      <Modal.Header onHide={close} closeButton>
        <Modal.Title>
          <div className="d-flex flex-column">
            Associa Google Authenticator
            <span className="text-muted">Inquadra QR Code</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 d-flex flex-column flex-center">
          <div className="d-flex flex-center">
            <img src={qrcode} />
          </div>
          <span
            className={clsx('alert alert-danger', !(!isNil(error) && qrcode === '') && 'd-none')}>
            Qualcosa è andato storto
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex flex-column flex-center">
          <span
            className={clsx(
              'alert alert-info',
              (testResult !== undefined || !isNil(error)) && 'd-none'
            )}>
            Prova il codice!
          </span>
          <span
            className={clsx(
              'alert alert-success',
              (testResult === undefined || testResult === false) && 'd-none'
            )}>
            Codice valido
          </span>
          <span
            className={clsx(
              'alert alert-danger',
              (testResult === undefined || testResult === true) && 'd-none'
            )}>
            Codice non valido
          </span>
          <span
            className={clsx(
              'alert alert-danger',
              (qrcode !== '' || isNil(error)) && 'd-none' && 'd-none'
            )}>
            Qualcosa è andato storto
          </span>
          <AuthCode
            containerClassName="d-flex flex-wrap flex-center"
            inputClassName="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
            allowedCharacters="numeric"
            onChange={onChange}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const Settings2FA: FC = () => {
  const { TwoFAEnabled, disable2FA } = useProfile();

  const [disable, setDisable] = useState<boolean>(false);
  const [showEnabling, setShowEnabling] = useState<boolean>(false);
  const [showAssociating, setShowAssociating] = useState<boolean>(false);
  const [showDisabling, setShowDisabling] = useState<boolean>(false);
  const [qrcode, setQRCode] = useState<string>('');

  const onDisable = async () => {
    setDisable(true);
    await disable2FA();
    setQRCode(qrcode);
    setDisable(false);
  };

  return (
    <div className="d-flex flex-column">
      <div className={clsx("notice d-flex", TwoFAEnabled ? "bg-light-primary" : "bg-light-warning", "rounded border-primary border border-dashed p-6")}>
        <KTSVG
          path="/media/icons/duotune/general/gen048.svg"
          className="svg-icon-2tx svg-icon-primary me-4"
        />
        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
          <div className="mb-3 mb-md-0 fw-bold">
            <h4 className="text-gray-800 fw-bolder">Autenticazione a due fattori</h4>
            <div className="fs-6 text-gray-600 pe-7">
              L'autenticazione a due fattori aggiunge un ulteriore livello di sicurezza al tuo
              account. Per effettuare il log in, dovrai inserire un codice a 6 cifre ottenibile
              solamente da quei dispositivi che avrai associato.
            </div>
          </div>
          <button
            onClick={() => {
              if (!TwoFAEnabled) setShowEnabling(true);
              else setShowAssociating(true);
            }}
            className="btn btn-primary px-6 align-self-center text-nowrap me-5 ">
            {!TwoFAEnabled ? 'Abilita' : 'Associa'}
          </button>
          <Enabling2FAModal show={showEnabling} close={() => setShowEnabling(false)} />
          <Associate2FAModal
            show={showAssociating}
            close={() => {
              setShowAssociating(false);
            }}
          />
          {TwoFAEnabled && (
            <>
              <a
                onClick={() => setShowDisabling(true)}
                href="#"
                className="btn btn-danger btn-sm px-6 align-self-center text-nowrap">
                Disabilita
              </a>
              <Modal show={showDisabling} onHide={() => setShowDisabling(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Disabilitare la 2FA?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="notice d-flex bg-light-danger rounded border-primary border border-dashed p-6">
                    È altamente sconsigliato disabilitare l'autenticazione a due fattori.
                  </div>
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowDisabling(false)}>
                    Cancella
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      disable2FA().then(() => setShowDisabling(false));
                    }}>
                    Conferma
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { Settings2FA };

import { ScansTable } from './tables/scans/ScansTable';
import React from 'react';

export function Overview() {
  return (
    <div className="card mb-5 mb-xl-10 p-5">
      <div className="row g-5 g-xxl-8">
        <ScansTable tableId="profile_overview_table_scans" />
      </div>
    </div>
  );
}

import { BestPractices } from '../best_practices/BestPractices';

const BestPracticesTab = () => (
  <div id="report_tab_bestPractices" className="container" style={{ display: 'none' }}>
    <div className="d-flex fle-start my-10 ms-5">
      <h2>Best Practices</h2>
    </div>
    <div className="card">
      <div className="card-body">
        <BestPractices />
      </div>
    </div>
  </div>
);

export { BestPracticesTab };

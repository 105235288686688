import { VulnerabilityTable } from '../tables/vulnerability/VulnerabilityTable';
import { ReportModal, ReportModalProps } from './ReportModal';
import React from 'react';

const VulnerabilityModal: React.FC<Omit<ReportModalProps, 'id'>> = ({ show, handleClose }) => {
  return (
    <ReportModal
      id="report_modal_vulnerabilities"
      title="Vulnerabilità"
      handleClose={handleClose}
      show={show}>
      <VulnerabilityTable tableId="fast_report_table_modal_vulnerabilities" />
    </ReportModal>
  );
};
export { VulnerabilityModal };

import { ExploitInfo } from '../infos/ExploitInfo';
import { ExploitModal } from '../modals/ExploitModal';
import { ReportCard } from './ReportCard';
import React from 'react';
import { useExploit } from '../providers/ReporterOSINTProvider';
import clsx from 'clsx';

type Props = {
  className: string;
};

const ExploitCard: React.FC<Props> = ({ className }: Props) => {
  const exploits = useExploit();

  return (
    <ReportCard
      className={clsx(className, '')}
      counter={exploits?.length}
      color={'#3ac4ce'}
      bgColor={'rgba(58, 196, 206, 0.15)'}
      description={'Possibili Attacchi'}
      InfoContent={ExploitInfo}
      Modal={ExploitModal}
    />
  );
};

export { ExploitCard };

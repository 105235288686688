import { IFAST } from 'src/_types';
import {ScoreData} from './_common';
import { isNil } from "lodash";
import { SCORE_VALUE } from '../ReporterScoreProvider';

const breach_number_score_function = function(breach: IFAST.Roll['breach']):
    ScoreData {
      const value = (() => {
        if (!isNil(breach) && breach.length === 0) {
          return undefined;
        }

        const count_passwords = breach.reduce(
            (passw, breach) =>
                passw + breach.data.flatMap((b) => b.password).length,
            0,
        );

        if (count_passwords > 8) return SCORE_VALUE.critical;
        if (count_passwords > 2) return SCORE_VALUE.high;
        if (count_passwords > 1) return SCORE_VALUE.medium;
        return SCORE_VALUE.low;
      })();

      return {
        value: value,
        name: 'Account compromessi',
      };
    }

const breach_score = function(breach?: IFAST.Roll['breach']):
    ScoreData[] {
      if (breach === undefined) {
        return [{value: undefined, name: 'Account compromessi'}];
      }

      return [
        breach_number_score_function(breach),
      ]
    }

export {
  breach_number_score_function,
  breach_score,
};
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { ProfileWrapper } from '../pages/profile/ProfilePage';
import { StartPage } from '../pages/start/StartPage';
import { FC, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { ReporterWrapper } from '../pages/reporter/normal/ReporterWrapper';
import { SupervisorWrapper } from '../pages/supervisor/SupervisorPage';
import { ReporterPDF } from '../pages/reporter/normal/ReporterPDF';

const PrivateRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route path="pdf" element={<ReporterPDF />} />
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/home" />} />
        {/* Pages */}
        <Route path="home" element={<StartPage />} />
        <Route path="report" element={<ReporterWrapper />} />
        <Route path="start" element={<StartPage />} />
        <Route path="profile" element={<ProfileWrapper />} />
        <Route path="supervision" element={<SupervisorWrapper />} />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
      {/* <Route path="pdf" element={<MyDocument />} /> */}
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };

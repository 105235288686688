import React, { useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';
import { useReporterState } from '../../../providers/ReporterRoot';

const DomainCell = (props: CellProps<IFAST.PartialServer, IFAST.PartialServer['domains']>) => {
  const FastState = useReporterState();

  const hostnames = props.cell.value as string[];

  if (hostnames === undefined) {
    if (FastState === 'running') {
      return <Spinner animation="border" />;
    }
    return <span className={`badge badge-light fs-base`}>-</span>;
  }

  return (
    <span>
      {hostnames.slice(0,5).map((h, i) => (
        <span key={`endpoints_table_hostname_cell_${props.row.index}_${i}`}>
          {h}
          <br />
        </span>
      ))}
    </span>
  );
};

export { DomainCell };

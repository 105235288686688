import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import AuthCode from 'react-auth-code-input';
import { Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface Props {
  onSubmit: (_code2fa: string) => Promise<boolean>;
  onBack?: () => void;
  onSuccess?: () => void;
}

const TwoFactorAuthentication: FC<Props> = ({ onSubmit, onBack }) => {
  const authcodeRef = useRef<any>();

  const [invalid, setInvalid] = useState<boolean | undefined>();

  const onChange = async (code: string) => {
    if (/\d{6}/.exec(code)) {
      onSubmit?.(code).then(setInvalid);
    } else {
      setInvalid(undefined);
    }
  };

  useEffect(() => {
    authcodeRef.current?.clear();
    authcodeRef.current?.focus();
  }, []);

  return (
    <div className="d-flex flex-column">
      <div className="fw-bold text-start fs-6 mb-1 ms-1">Inserisci il codice di 6 cifre</div>
      <div>
        <AuthCode
          containerClassName="d-flex flex-wrap flex-center"
          inputClassName="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
          allowedCharacters="numeric"
          onChange={onChange}
          ref={authcodeRef}
        />
      </div>
      <span className={clsx('alert alert-danger', (invalid === undefined || invalid === true) && 'd-none')}>
        Codice non valido
      </span>
      <span className={clsx('alert alert-success', (invalid === undefined || invalid === false) && 'd-none')}>Codice valido</span>
      <button
        type="button"
        id="kt_2fa_cancel_button"
        className="btn bg-fast opacity-75"
        onClick={onBack}>
          Indietro
      </button>
    </div>
  );
};

export { TwoFactorAuthentication };
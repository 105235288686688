/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import React, { useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { CellProps } from 'react-table';
import { IFAST } from 'src/_types';

const CVSSCell = (
  props: CellProps<IFAST.Roll['vulnerability'][0], IFAST.Roll['vulnerability'][0]['cvssv2_score']>
) => {
  const color = useMemo(() => {
    const value = props.cell.value;
    if (value > 7) return 'danger';
    if (value > 4) return 'warning';
    if (value > 0) return 'info';
    return 'success';
  }, [props.cell.value]);

  return (
    <div className="d-flex flex-column flex-center">
    <span className={`badge badge-light-${color} fs-base`}>
      {props.cell.value}
    </span>
    <div className="d-flex flex-row flex-center">
      <span className="text-muted fs-8">Good&nbsp;</span>
      <span className={`badge badge-light-${color} fs-base`}>
        <div className="d-inline-block rounded  w-75px">
          <ProgressBar
            className="bg-gray-300i "
            striped
            variant={color}
            now={props.cell.value * 10}
          />
        </div>
      </span>
      <span className="text-muted fs-8">Bad&nbsp;</span>
    </div>
    </div>
  );
};

export { CVSSCell };

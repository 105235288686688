import { InfoModal } from '../components/InfoModal';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';

type Props = {
  className?: string;
  absolute?: boolean;
  size?: SizeProp;
};

const ReputationInfo: FC<Props> = (props) => (
  <InfoModal color={''} {...props} title={'Reputazione Indirizzi IP'}>
    <p>
      L'IP Reputation misura la qualità comportamentale di un indirizzo IP. Se un indirizzo IP invia
      e-mail autentiche e prive di spam, ottiene un punteggio di reputazione IP positivo.
      <br />
      D'altra parte, se associato a spam in blocco, malware, domini pericolosi o posizioni sospette,
      un indirizzo IP avrà una cattiva reputazione.
    </p>
  </InfoModal>
);

export { ReputationInfo };

import React, { PropsWithChildren } from 'react';
import { Modal } from 'react-bootstrap';
import { KTSVG } from 'src/_metronic/helpers';

export type ReportModalProps = {
  id?: string;
  title?: string;
  show: boolean;
  handleClose: () => void;
};

const ReportModal: React.FC<PropsWithChildren<ReportModalProps>> = ({
  id,
  title,
  show,
  handleClose,
  children
}) => {
  return (
    <Modal
      className="modal modal-xl"
      id={id}
      backdrop={true}
      onHide={handleClose}
      aria-hidden="true"
      tabIndex="-1"
      show={show}
      animation={true}>
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
      </div>
      <div className="modal-body">{children}</div>
      <div className="modal-footer p-2">
        <button className="btn" onClick={handleClose}>
          Chiudi
        </button>
      </div>
    </Modal>
  );
};
export { ReportModal };

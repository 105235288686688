/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import moment from 'moment';
import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Column, Renderer, FilterProps, CellProps } from 'react-table';
import { IFAST } from 'src/_types';
import { ReporterRootLocationState } from 'src/app/pages/reporter/normal/providers/ReporterRoot';
import { LocaleDateTime } from 'src/app/components/LocalDateTime';

const scansColumns: ReadonlyArray<Column<IFAST.API.Responses.Supervisor[0]>> = [
  {
    Header: 'Dominio',
    accessor: 'domain',
    disableFilters: true
  },
  {
    Header: 'Utente',
    accessor: 'email',
    disableFilters: true
  },
  // {
  //   Header: 'Progresso',
  //   accessor: (v) => v.progress.toFixed(2) + '%',
  //   disableFilters: true
  // },
  {
    Header: 'Data',
    accessor: 'datetime_created',
    Cell: (
      props: CellProps<
        IFAST.API.Responses.Supervisor[0],
        IFAST.API.Responses.Supervisor[0]['datetime_created']
      >
    ) => <LocaleDateTime date={props.cell.value} />,
    disableFilters: true
  },
  {
    Header: 'Data',
    accessor: 'datetime_completed',
    Cell: (
      props: CellProps<
        IFAST.API.Responses.Supervisor[0],
        IFAST.API.Responses.Supervisor[0]['datetime_completed']
      >
    ) => <LocaleDateTime date={props.cell.value} />,
    disableFilters: true
  }
];

export { scansColumns };

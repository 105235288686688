import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FC } from 'react';
import { InfoModal } from '../components/InfoModal';

type Props = {
  className?: string;
  absolute?: boolean;
  size?: SizeProp;
};

const VulnerabilityInfo: FC<Props> = (props) => (
  <InfoModal color={'rgba(253, 141, 8, 0.5)'} {...props} title={'Vulnerabilità'}>
    <p>
      Le vulnerabilità informatiche sono veicolate da malfunzionamenti, mancati aggiornamenti e
      configurazioni non corrette e/o errori non gestiti presenti nei software.
    </p>
    <p>
      Tantissimi sono gli attacchi cyber derivanti dallo sfruttamento di vulnerabilità informatiche.
    </p>
  </InfoModal>
);

export { VulnerabilityInfo };

import { FC } from 'react';
import { CellProps } from 'react-table';
import { IFAST } from 'src/_types';


const PasswordsCell: FC<CellProps<IFAST.Roll['breach'][0]> > = (props) => {
  return (
    <div>
      {(props.row.original.data).map((b, i) => (
        <p key={`tr_password_${i}`}>{b.has_password ? b.password : '-'}</p>
      ))}
    </div>
  );
};

export { PasswordsCell };

/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosResponse } from 'axios';
import React, { FC, createContext, useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { WithChildren } from 'src/_metronic/helpers';
import { useAPI } from 'src/_metronic/layout/core';
import { IFAST } from 'src/_types';
import { useAuth } from 'src/app/modules/auth';

export interface SupervisorContextModel {
  scans: IFAST.API.Responses.Supervisor;
}

const SupervisorContext = createContext<SupervisorContextModel>({
  scans: []
});

const SupervisorProvider: FC<WithChildren> = ({ children }) => {
  const { api } = useAPI();
  const [scans, setScans] = useState<IFAST.API.Responses.Supervisor>([]);
  const { isSupervisor } = useAuth();

  if (isSupervisor === false) return <Navigate to="/home" />;

  useEffect(() => {
    axios
      .get<IFAST.API.Responses.Supervisor>(`${api}/fast/supervisor`)
      .then((response) => {
        setScans(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const value: SupervisorContextModel = {
    scans
  };
  return <SupervisorContext.Provider value={value}>{children}</SupervisorContext.Provider>;
};

function useSupervisor() {
  return useContext(SupervisorContext);
}

export { SupervisorProvider, useSupervisor };

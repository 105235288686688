import { useProfile } from '../../ProfileProvider';
import { scansColumns } from './columns/_columns';
import { useMemo, FC } from 'react';
import { ColumnInstance, Row, useFilters, usePagination, useTable } from 'react-table';
import { IFAST } from 'src/_types';
import { CustomHeaderColumn } from 'src/app/pages/reporter/normal/tables/_common/CustomHeaderColumn';
import { CustomRow } from 'src/app/pages/reporter/normal/tables/_common/CustomRow';
import { ReportPagination } from 'src/app/pages/reporter/normal/tables/_common/ReportPagination';

interface Props {
  tableId: string;
  useGoToPage?: boolean;
}

const ScansTable: FC<Props> = ({ tableId }) => {
  const { scans } = useProfile();

  const columns = useMemo(() => scansColumns, []);

  const data = useMemo(() => scans, [scans]);

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable<IFAST.API.Responses.Olds[0]>(
    {
      columns: columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    usePagination
  );

  if (!scans) {
    return (
      <div>
        Nessun scansione precedente.
      </div>
    );
  }

  return (
    <div className="table-responsive">
      <table
        id={tableId}
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        {...getTableProps()}>
        <thead>
          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
            {headers.map((column: ColumnInstance<IFAST.API.Responses.Olds[0]>) => (
              <CustomHeaderColumn<IFAST.API.Responses.Olds[0]> key={column.id} column={column} />
            ))}
          </tr>
        </thead>
        <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
          {page.length > 0 ? (
            page.map((row: Row<IFAST.API.Responses.Olds[0]>, i) => {
              prepareRow(row);
              return <CustomRow<IFAST.API.Responses.Olds[0]> row={row} key={`row-${i}-${row.id}`} />;
            })
          ) : (
            <tr>
              <td colSpan={columns.length}>
                <div className="d-flex text-center w-100 align-content-center justify-content-center">
                  Nessun scansione
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <ReportPagination
        showGoToPage={false}
        pageIndex={pageIndex}
        pageCount={pageCount}
        pageSize={pageSize}
        pageOptions={pageOptions}
        pageSizes={[10, 25, 50]}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        setPageSize={setPageSize}
      />
    </div>
  );
};

export { ScansTable };

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, PropsWithChildren } from 'react';
import { useId } from 'react';
import { Modal } from 'react-bootstrap';

type Props = PropsWithChildren<{
  color: string;
  className?: string;
  title: string;
  absolute?: boolean;
  size?: SizeProp;
}>;

const InfoModal: React.FC<Props> = ({
  color,
  className = '',
  title,
  children,
  size = '2x',
  absolute = true
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const show = (e?: any) => {
    setShowModal(true);
    e.stopPropagation();
  };
  const close = (e?: any) => {
    setShowModal(false);
    e.stopPropagation();
  };

  const id = useId();

  return (
    <div
      className={`${className} m-5 cursor-pointer`}
      style={absolute ? { position: 'absolute', top: 0, right: 0 } : {}}>
      <a onClick={show}>
        <FontAwesomeIcon className={'opacity-75'} color={color} icon={faInfoCircle} size={size} />
      </a>
      <Modal
        className="modal"
        id={id}
        backdrop={true}
        onHide={show}
        aria-hidden="true"
        tabIndex="-1"
        show={showModal}
        animation={true}>
        <div className="p-10 pb-5 d-flex flex-column flex-start">
          <h2>{title}:</h2>
          <div className="m-auto my-5 fs-4">{children}</div>
          <div className="w-100 d-flex flex-end">
            <button className="btn btn-sm" onClick={close}>
              Chiudi
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export { InfoModal };

import { MenuComponent } from '../assets/ts/components';
import { ReactNode } from 'react';

type WithChildren = {
  children?: ReactNode;
};

const reInitMenu = () => {
  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 500);
};

export { type WithChildren, reInitMenu };

/* eslint-disable react/prop-types */
// TODO: upgrade to latest eslint tooling
import React, { useMemo } from 'react';
import { Column, CellProps } from 'react-table';
import { IFAST } from 'src/_types';

const IsVulnerableCell = (props: CellProps<IFAST.Roll['port'][0]>) => {

  const color = useMemo(() => {
    const value = props.cell.value;
    if (value) return 'danger';
    return 'info';
  }, [props.cell.value]);

  return (
    <span className={`badge badge-light-${color} fs-base`}>
      {props.cell.value ? 'Vulnerabile' : 'Ok'}
    </span>
  );
};

export { IsVulnerableCell };

/* eslint-disable react-hooks/exhaustive-deps */
import { useLayout } from '../../core';
import clsx from 'clsx';
import { FC } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from 'src/_metronic/helpers';
import { HeaderUserMenu } from 'src/_metronic/partials';
import { useAuth } from 'src/app/modules/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const itemClass = 'ms-1 ms-lg-3';
const userAvatarClass = 'symbol-35px symbol-lg-50px';

const FastTitle: FC<{ hideTitle?: boolean }> = ({ hideTitle = false }) => {
  return (
    <div className="m-5">
      <Link to="/start">
        <img
          className="w-200px"
          alt="fast-logo"
          src={toAbsoluteUrl('/media/fast/logos/fast.png')}
        />
      </Link>
    </div>
  );
};

const UserMenu = () => {
  const { currentUser } = useAuth();
  return (
  <div className={clsx('app-navbar-item', itemClass)}>
    <div
      className={clsx('d-flex flex-row align-items-end cursor-pointer symbol', userAvatarClass)}
      data-kt-menu-trigger="{default: 'click'}"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="bottom-end">
      {currentUser!.image ? (
        <img src={currentUser!.image} alt="FAST" />
      ) : (
        <FontAwesomeIcon icon={faBars} size={'2x'}/>
      )}
    </div>
    <HeaderUserMenu />
  </div>
)};

export function HeaderWrapperEmpty() {
  const { config, classes } = useLayout();
  const { currentUser } = useAuth();

  if (!config.app?.header?.display) {
    return null;
  }

  return (
    <div id="kt_app_header" className="app-header h-100px bg-fast">
      <div
        id="kt_app_header_container"
        className="app-container container-xxl d-flex align-items-center justify-content-between">
        <FastTitle />
        <div className="d-flex flex-row flex-center">
          <span className="d-none d-lg-block d-xl-block app-navbar-item me-lg-2 me-xl-2 text-white fs-2 fw-none">
            {currentUser?.firstname} {currentUser?.lastname}
          </span>
          <UserMenu />
        </div>
      </div>
    </div>
  );
}

import { ScansTable } from './tables/scans/Table';
import axios from 'axios';
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormText, FormControl, FormLabel, FormGroup } from 'react-bootstrap';
import { WithChildren } from 'src/_metronic/helpers';
import { useAPI } from 'src/_metronic/layout/core';

export interface ConfigContexModel {
  get: (...args: string[]) => any;
  root: any;
}
const ConfigContex = createContext<ConfigContexModel>({ get: () => [], root: {} });

const ConfigProvider: FC<WithChildren> = ({ children }) => {
  const api = useAPI().api;
  const [root, setRoot] = useState<any>();

  useEffect(() => {
    axios.get(`${api}/configuration/get`).then((response) => setRoot(response.data));
  }, []);

  const get = useCallback(
      (...selectors: string[]) => {
        return [...selectors].map((s) =>
          s
            // eslint-disable-next-line no-useless-escape
            .replace(/\[([^\[\]]*)\]/g, '.$1.')
            .split('.')
            .filter((t) => t !== '')
            .reduce((prev, cur) => prev && prev[cur], root)
        );
      },
    [root]
  );

  return <ConfigContex.Provider value={{ get, root }}>{children}</ConfigContex.Provider>;
};

const ConfigForm: FC<{ path: string; type: string; title?: string }> = ({ path, type, title }) => {
  const { get } = useContext(ConfigContex);

  return (
    <FormGroup>
      <FormLabel htmlFor="form_static">{ title ? title : path }</FormLabel>
      <FormControl id="form_static" type={type} defaultValue={get(path)} />
    </FormGroup>
  );
};

export function Configuration() {
  return (
    <ConfigProvider>
      <div className="card mb-5 mb-xl-10 p-5">
        <div className="row g-5 g-xxl-8 d-flex flex-column">
          <ConfigForm path="root.port" type="number" />
          <ConfigForm path="root.static.path" type="text" />
        </div>
      </div>
    </ConfigProvider>
  );
}

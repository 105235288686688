import React, { ReactElement } from 'react';
import { IFAST } from 'src/_types';

const FASTScoreCritical = () => (
  <div className="p-10">
    <p className="lead fs-2 fw-bold">
      FAST Score <u>CRITICO</u>!
    </p>
    <p>
      Le informazioni aziendali raccolte pubblicamente e accessibili a chiunque sono molto rilevanti
      dal punto di vista di un criminal hacker e descrivono una situazione assolutamente critica.
    </p>
    <p>
      La superficie di attacco è molto ampia. La probabilità di ricevere un attacco informatico e
      l'impatto che esso avrebbe sull'operatività aziendale è molto importante.
    </p>
    <p>
      Si consiglia di avviare il prima possibile un processo di valutazione dei rischi cyber al fine
      di preservare la continuità del business aziendale.
    </p>
  </div>
);

const FASTScoreHigh = () => (
  <div className="p-10">
    <p className="lead fs-2 fw-bold">
      FAST Score <u>Alto</u>!
    </p>
    <p>
      Le informazioni aziendali raccolte pubblicamente e accessibili a chiunque, sono molto
      significative dal punto di vista di un criminal hacker e descrivono una situazione pericolosa.
      La superficie di attacco è molto ampia.
    </p>
    <p>
      La probabilità di ricevere un attacco informatico e l'impatto che esso avrebbe
      sull'operatività aziendale è rilevante.
    </p>
    <p>
      Si consiglia vivamente di avviare un processo di valutazione dei rischi cyber che permetta di
      valutare i processi e le misure tecnico organizzative in essere dal punto di vista della
      sicurezza informatica e di preservare la continuità del business aziendale.
    </p>
  </div>
);

const FASTScoreMedium = () => (
  <div className="p-10">
    <p className="lead fs-2 fw-bold">
      FAST Score <u>Medio</u>!
    </p>
    <p>
      Le informazioni aziendali raccolte pubblicamente e accessibili a chiunque sono relativamente
      significative dal punto di vista di un criminal hacker e descrivono una situazione abbastanza
      controllata.
    </p>
    <p>
      La superficie di attacco è limitata: la probabilità di ricevere un attacco informatico e
      l'impatto che esso avrebbe sull'operatività aziendale è modesta.
    </p>
    <p>
      Si consiglia di avviare un processo di valutazione dei rischi cyber che permetta di valutare i
      processi e le misure tecnico organizzative in essere dal punto di vista della sicurezza
      informatica e di preservare la continuità del business aziendale.
    </p>
  </div>
);

const FASTScoreLow = () => (
  <div className="p-10">
    <p className="lead fs-2 fw-bold">
      FAST Score <u>Basso</u>!
    </p>
    <p>
      Le informazioni aziendali raccolte pubblicamente e accessibili a chiunque sono poco rilevanti
      dal punto di vista di un criminal hacker e descrivono una situazione apparentemente
      controllata.
    </p>
    <p>La superficie di attacco è ridotta.</p>
    <p>
      Si consiglia comunque di avviare un processo di valutazione dei rischi cyber che permetta di
      valutare i processi e le misure tecnico organizzative in essere dal punto di vista della
      sicurezza informatica e di preservare la continuità del business aziendale.
    </p>
  </div>
);

const ScoreLevels: { [key in IFAST.Score.Levels]: () => ReactElement } = {
  critical: FASTScoreCritical,
  high: FASTScoreHigh,
  medium: FASTScoreMedium,
  low: FASTScoreLow
};


export { ScoreLevels, FASTScoreCritical, FASTScoreHigh, FASTScoreMedium, FASTScoreLow };

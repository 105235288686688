import { useLookalikeDomain } from '../providers/ReporterOSINTProvider';
import { ReportCard } from './ReportCard';
import clsx from 'clsx';
import React from 'react';
import { LookalikeDomainModal } from '../modals/LookalikeDomainModal';
import { LookalikeDomainInfo } from '../infos/LookalikeDomainInfo';

type Props = {
  className: string;
};

const LookalikeDomainCard: React.FC<Props> = ({ className }) => {
  const lookalikeDomains = useLookalikeDomain();

  return (
    <ReportCard
      className={clsx(className, '')}
      counter={lookalikeDomains?.length}
      color={'#118af5'}
      bgColor={'rgba(17, 138, 245, 0.15)'}
      description={'Domini Simili'}
      InfoContent={LookalikeDomainInfo}
      Modal={LookalikeDomainModal}
    />
  );
};

export { LookalikeDomainCard };

import { useReporterUUID } from '../providers/ReporterRoot';
import { useScore } from '../providers/ReporterScoreProvider';
import axios from 'axios';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAPI } from 'src/_metronic/layout/core';

const PDFButton = () => {
  const api = useAPI().api;
  const FastUUID = useReporterUUID();
  const score = useScore();
  const [pdf_loading, setPDFLoading] = useState<boolean>(false);

  const downloadPDF = () => {
    axios
      .post(
        `${api}/fast/pdf`,
        { ...score, uuid: FastUUID },
        {
          responseType: 'blob'
        }
      )
      .then((response) => {
        const href = window.URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'FAST Report.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);
      })
      .finally(() => {
        setPDFLoading(false);
      });
    setPDFLoading(true);
  };
  return (
    <div onClick={downloadPDF} className="btn btn-dark btn-sm" style={{ borderRadius: '3rem' }}>
      {pdf_loading === false ? (
        <span className="fw-bold lh-1">Esporta PDF</span>
      ) : (
        <Spinner animation="border" />
      )}
    </div>
  );
};

export { PDFButton };
